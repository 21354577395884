
import React, { useState, useRef, useEffect, useContext } from 'react';

import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, getDoc, doc, getFirestore, limit } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { AppContext } from './AppContext';
import Modal from 'react-modal';
const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = height * 0.18;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.16;





const genresList = [
  { label: "Alternative", value: "Alternative" },
  { label: "Blues", value: "blues" },
  { label: "Classical", value: "classical" },
  { label: "Country", value: "country" },
  { label: "Electronic/Dance", value: "electronic_dance" },
  { label: "Folk", value: "folk" },
  { label: "Rap", value: "hip_hop_rap" },
  { label: "Indie", value: "indie" },
  { label: "Jazz", value: "jazz" },
  { label: "Latin", value: "latin" },
  { label: "Metal", value: "metal" },
  { label: "Pop", value: "pop" },
  { label: "R&B/Soul", value: "rnb_soul" },
  { label: "Reggae", value: "reggae" },
  { label: "Rock", value: "rock" },
  { label: "World/International", value: "world_international" }
];




const subgenresList = {
  rock: [
    { label: "Alternative Rock", value: "alternative_rock" },
    { label: "Indie Rock", value: "indie_rock" },
    { label: "Classic Rock", value: "classic_rock" },
    { label: "Hard Rock", value: "hard_rock" },
    { label: "Progressive Rock", value: "progressive_rock" },
    { label: "Psychedelic Rock", value: "psychedelic_rock" },
    { label: "Folk Rock", value: "folk_rock" },
    { label: "Grunge", value: "grunge" },
    { label: "Glam Rock", value: "glam_rock" }
  ],
  pop: [
    { label: "Pop Rock", value: "pop_rock" },
    { label: "Synth-pop", value: "synth_pop" },
    { label: "Dance Pop", value: "dance_pop" },
    { label: "Teen Pop", value: "teen_pop" },
    { label: "Electropop", value: "electropop" },
    { label: "J-Pop", value: "j_pop" },
    { label: "Bubblegum Pop", value: "bubblegum_pop" },
    { label: "Indie Pop", value: "indie_pop" },
    { label: "Art Pop", value: "art_pop" }
  ],
  jazz: [
    { label: "Bebop", value: "bebop" },
    { label: "Swing", value: "swing" },
    { label: "Fusion", value: "fusion" },
    { label: "Smooth Jazz", value: "smooth_jazz" },
    { label: "Cool Jazz", value: "cool_jazz" },
    { label: "Modal Jazz", value: "modal_jazz" },
    { label: "Free Jazz", value: "free_jazz" },
    { label: "Latin Jazz", value: "latin_jazz" },
    { label: "Jazz Funk", value: "jazz_funk" },
    { label: "Acid Jazz", value: "acid_jazz" }
  ],
  classical: [
    { label: "Baroque", value: "baroque" },
    { label: "Romantic", value: "romantic" },
    { label: "Classical Period", value: "classical_period" },
    { label: "Medieval", value: "medieval" },
    { label: "Renaissance", value: "renaissance" },
    { label: "Contemporary Classical", value: "contemporary_classical" },
    { label: "Minimalism", value: "minimalism" },
    { label: "Impressionism", value: "impressionism" },
    { label: "Neoclassicism", value: "neoclassicism" },
    { label: "Avant-Garde", value: "avant_garde" }
  ],
  hip_hop: [
    { label: "Rap", value: "rap" },
    { label: "Old School Hip-Hop", value: "old_school_hip_hop" },
    { label: "Gangsta Rap", value: "gangsta_rap" },
    { label: "Trap", value: "trap" },
    { label: "East Coast Hip-Hop", value: "east_coast_hip_hop" },
    { label: "West Coast Hip-Hop", value: "west_coast_hip_hop" },
    { label: "Southern Hip-Hop", value: "southern_hip_hop" },
    { label: "Boom Bap", value: "boom_bap" },
    { label: "Experimental Hip-Hop", value: "experimental_hip_hop" },
    { label: "Conscious Hip-Hop", value: "conscious_hip_hop" },
    { label: "Hyphy", value: "hyphy" }
  ],
  electronic: [
    { label: "Techno", value: "techno" },
    { label: "House", value: "house" },
    { label: "Dubstep", value: "dubstep" },
    { label: "Drum and Bass", value: "drum_and_bass" },
    { label: "Electro", value: "electro" },
    { label: "IDM (Intelligent Dance Music)", value: "idm" },
    { label: "Synthwave", value: "synthwave" },
    { label: "Breakbeat", value: "breakbeat" }
  ],
  country: [
    { label: "Traditional Country", value: "traditional_country" },
    { label: "Country Rock", value: "country_rock" },
    { label: "Country Rap", value: "country_rap" },
    { label: "Honky Tonk", value: "honky_tonk" },
    { label: "Bluegrass", value: "bluegrass" },
    { label: "Outlaw Country", value: "outlaw_country" },
    { label: "Contemporary Country", value: "contemporary_country" },
    { label: "Country Pop", value: "country_pop" },
    { label: "Cajun Country", value: "cajun_country" },
    { label: "Western Swing", value: "western_swing" },
    { label: "Alternative Country", value: "alternative_country" }
  ],
  blues: [
    { label: "Delta Blues", value: "delta_blues" },
    { label: "Chicago Blues", value: "chicago_blues" },
    { label: "Electric Blues", value: "electric_blues" },
    { label: "Texas Blues", value: "texas_blues" },
    { label: "Acoustic Blues", value: "acoustic_blues" },
    { label: "Piedmont Blues", value: "piedmont_blues" },
    { label: "Jump Blues", value: "jump_blues" },
    { label: "Swamp Blues", value: "swamp_blues" },
    { label: "Soul Blues", value: "soul_blues" },
    { label: "Blues Rock", value: "blues_rock" }
  ],
  reggae: [
    { label: "Roots Reggae", value: "roots_reggae" },
    { label: "Dub", value: "dub" },
    { label: "Dancehall", value: "dancehall" },
    { label: "Lovers Rock", value: "lovers_rock" },
    { label: "Reggae Fusion", value: "reggae_fusion" },
    { label: "Ragga", value: "ragga" },
    { label: "Rocksteady", value: "rocksteady" },
    { label: "Ska", value: "ska" },
    { label: "Digital Reggae", value: "digital_reggae" },
  ],
  folk: [
    { label: "Traditional Folk", value: "traditional_folk" },
    { label: "Contemporary Folk", value: "contemporary_folk" },
    { label: "Folk Rock", value: "folk_rock" },
    { label: "Indie Folk", value: "indie_folk" },
    { label: "Progressive Folk", value: "progressive_folk" },
    { label: "Americana", value: "americana" },
    { label: "Celtic Folk", value: "celtic_folk" },
    { label: "Folk Pop", value: "folk_pop" },
    { label: "Anti-Folk", value: "anti_folk" },
    { label: "Neo-Folk", value: "neo_folk" }
  ],
    thrash_metal: [
  { label: "Classic Thrash Metal", value: "classic_thrash_metal" },
  { label: "Crossover Thrash", value: "crossover_thrash" },
  { label: "Technical Thrash Metal", value: "technical_thrash_metal" },
  { label: "Groove Metal", value: "groove_metal" },
  { label: "Teutonic Thrash Metal", value: "teutonic_thrash_metal" },
  { label: "Bay Area Thrash Metal", value: "bay_area_thrash_metal" },
  { label: "Blackened Thrash Metal", value: "blackened_thrash_metal" },
  { label: "Deathrash", value: "deathrash" },
  { label: "Melodic Thrash Metal", value: "melodic_thrash_metal" },
  { label: "Thrashcore", value: "thrashcore" }
],
  heavy_metal: [
    { label: "Traditional Heavy Metal", value: "traditional_heavy_metal" },
    { label: "Glam Metal", value: "glam_metal" },
    { label: "New Wave of British Heavy Metal (NWOBHM)", value: "nwobhm" },
    { label: "Power Metal", value: "power_metal" },
    { label: "Neoclassical Metal", value: "neoclassical_metal" },
    { label: "Doom Metal", value: "doom_metal" },
    { label: "Gothic Metal", value: "gothic_metal" },
    { label: "Symphonic Metal", value: "symphonic_metal" },
    { label: "Speed Metal", value: "speed_metal" },
    { label: "Folk Metal", value: "folk_metal" }
  ],
  death_metal: [
  { label: "Melodic Death Metal", value: "melodic_death_metal" },
  { label: "Technical Death Metal", value: "technical_death_metal" },
  { label: "Brutal Death Metal", value: "brutal_death_metal" },
  { label: "Death-Doom", value: "death_doom" },
  { label: "Deathcore", value: "deathcore" },
  { label: "Blackened Death Metal", value: "blackened_death_metal" },
  { label: "Progressive Death Metal", value: "progressive_death_metal" },
  { label: "Deathgrind", value: "deathgrind" },
  { label: "Slam Death Metal", value: "slam_death_metal" },
  { label: "Industrial Death Metal", value: "industrial_death_metal" }
],

  // ... other genre sublists
};

const tagsList = [
  { label: "Autobiographical", value: "autobiographical" },
  { label: "Contemporary Beats", value: "contemporary_beats" },
  { label: "Cultural Critique", value: "cultural_critique" },
  { label: "Dark Themes", value: "dark_themes" },
  { label: "Energetic", value: "energetic" },
  { label: "Gritty", value: "gritty" },
  { label: "Hip-Hop", value: "hip_hop" },
  { label: "Innovative", value: "innovative" },
  { label: "Intense", value: "intense" },
  { label: "Lyrical Rap", value: "lyrical_rap" },
  { label: "Melodic Flow", value: "melodic_flow" },
  { label: "Moody", value: "moody" },
  { label: "Personal Storytelling", value: "personal_storytelling" },
  { label: "Raw Emotion", value: "raw_emotion" },
  { label: "Realism", value: "realism" },
  { label: "Reflective", value: "reflective" },
  { label: "Social Commentary", value: "social_commentary" },
  { label: "Street Rap", value: "street_rap" },
  { label: "Thought-Provoking", value: "thought_provoking" },
  { label: "Trap Influences", value: "trap_influences" },
];

const getCurrentUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : null;
};


const ReleaseSPNCast = () => {

const { 

      currentUser, setUser,
      currentlyReleasing, setCurrentlyReleasing,
      showSuccessAlertReleasedMusic, setShowSuccessAlertReleasedMusic,
      showSuccessAlertReleasedMusicVideo, setShowSuccessAlertReleasedMusicVideo
} = useContext(AppContext);

const artworkInputRef = useRef(null);


const [showPickerModal, setShowPickerModal] = useState(false);
const [selectedReleaseId, setSelectedReleaseId] = useState(null);


  const [albumMode, setAlbumMode] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({
    artistName: '',
    albumName: '',
    artworkURL: '',
    genres: [],
    tags: [],
    artistFeatures: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
  });
  const [editingAlbumDetails, setEditingAlbumDetails] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState('Alternative');
  const [selectedSubgenre, setSelectedSubgenre] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [producerName, setProducerName] = useState('');
  const [producerTrackName, setProducerTrackName] = useState('');
  const [songwriterName, setSongwriterName] = useState('');
  const [songDetails, setSongDetails] = useState([]);
  const [songTitle, setSongTitle] = useState('');
  const [songArtworkURL, setSongArtworkURL] = useState('');
  const [songUri, setSongUri] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [lyricsInput, setLyricsInput] = useState('');

  const [showCollaborators, setShowCollaborators] = useState(false);
  const [collaborators, setCollaborators] = useState([
    { name: '', userId: '', percentage: '', searchQuery: '', searchResults: [], isSearching: false }
  ]);
  
  const handleToggleCollaborators = () => {
    setShowCollaborators(!showCollaborators);
  };
  

  
  const handleAddCollaborator = () => {
    setCollaborators([...collaborators, { name: '', email: '', percentage: '' }]);
  };
  
  const handleRemoveCollaborator = (index) => {
    const updatedCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(updatedCollaborators);
  };

  const multiPersonFields = ['host', 'guest', 'actors', 'interviewee', 'participants', 'moderator'];

const [searchQueryHost, setSearchQueryHost] = useState('');
const [searchResultsHost, setSearchResultsHost] = useState([]);
const [isSearchingHost, setIsSearchingHost] = useState(false);

const [searchQueryGuest, setSearchQueryGuest] = useState('');
const [searchResultsGuest, setSearchResultsGuest] = useState([]);
const [isSearchingGuest, setIsSearchingGuest] = useState(false);

const [searchQueryInterviewee, setSearchQueryInterviewee] = useState('');
const [searchResultsInterviewee, setSearchResultsInterviewee] = useState([]);
const [isSearchingInterviewee, setIsSearchingInterviewee] = useState(false);

const [searchQueryParticipants, setSearchQueryParticipants] = useState('');
const [searchResultsParticipants, setSearchResultsParticipants] = useState([]);
const [isSearchingParticipants, setIsSearchingParticipants] = useState(false);

const [searchQueryModerator, setSearchQueryModerator] = useState('');
const [searchResultsModerator, setSearchResultsModerator] = useState([]);
const [isSearchingModerator, setIsSearchingModerator] = useState(false);

const [searchQueryArtistName, setSearchQueryArtistName] = useState('');
const [searchResultsArtistName, setSearchResultsArtistName] = useState([]);
const [isSearchingArtistName, setIsSearchingArtistName] = useState(false);

const [searchQueryProducers, setSearchQueryProducers] = useState('');
const [searchResultsProducers, setSearchResultsProducers] = useState([]);
const [isSearchingProducers, setIsSearchingProducers] = useState(false);

const [searchQuerySongwriters, setSearchQuerySongwriters] = useState('');
const [searchResultsSongwriters, setSearchResultsSongwriters] = useState([]);
const [isSearchingSongwriters, setIsSearchingSongwriters] = useState(false);

const [searchQueryCollaborator, setSearchQueryCollaborator] = useState('');
const [searchResultsCollaborator, setSearchResultsCollaborator] = useState([]);
const [isSearchingCollaborator, setIsSearchingCollaborator] = useState(false);

const [searchQueryArtistFeatures, setSearchQueryArtistFeatures] = useState('');
const [searchResultsArtistFeatures, setSearchResultsArtistFeatures] = useState([]);
const [isSearchingArtistFeatures, setIsSearchingArtistFeatures] = useState(false);

const [searchQueryRecordLabels, setSearchQueryRecordLabels] = useState('');
const [searchResultsRecordLabels, setSearchResultsRecordLabels] = useState([]);
const [isSearchingRecordLabels, setIsSearchingRecordLabels] = useState(false);


const [editingLinkedTrack, setEditingLinkedTrack] = useState(false);
const [selectedTrackForEdit, setSelectedTrackForEdit] = useState(null);



const [formMode, setFormMode] = useState('add');

  const [displayedArtwork, setDisplayedArtwork] = useState('');
  const [isSearching, setIsSearching] = useState(false);
const firestore = getFirestore();
const usersRef = collection(firestore, 'users');
const [videoDisplayName, setvideoDisplayName] = useState('');

const [addingSongs, setAddingSongs] = useState(false);
const [selectedTrack, setSelectedTrack] = useState(1);
const [currentTrackNumber, setCurrentTrackNumber] = useState(1);
const [currentForm, setCurrentForm] = useState('Album Details');


const [currentTrack, setCurrentTrack] = useState(1);
const [currentTrackDetails, setCurrentTrackDetails] = useState({
  title: '',
  artistName: '',
  artistFeatures: [],
  artworkURL: '',
  songURL: '',
  genres: [],
  tags: [],
  producers: [],
  songwriters: [],
  recordLabels: [],
  lyrics: '',
  videoDisplayName: '',
  trackNumber: null,
});
    

const [tracks, setTracks] = useState([]);
const [trackDetails, setTrackDetails] = useState({ title: '', artworkURL: '', songURL: '' });
const [currentPickerSelection, setCurrentPickerSelection] = useState('Album Details');

const [albumDetailsVisible, setAlbumDetailsVisible] = useState(false);
const [trackDetailsVisible, setTrackDetailsVisible] = useState(new Array(10).fill(false));

const [userReleases, setUserReleases] = useState({ albums: [], singles: [] });

useEffect(() => {
  const fetchUserReleases = async () => {
    const userId = getCurrentUserId();
    const userRef = doc(firestore, 'users', userId);

    try {
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const albums = userData.albums || [];
        const singles = userData.singles || [];

        const processedAlbums = albums.map(album => ({
          ...album,
          tracks: album.songs.map(song => ({ ...song })).sort((a, b) => a.trackNumber - b.trackNumber),
        }));

        setUserReleases({
          albums: processedAlbums,
          singles: singles.map(single => ({ ...single })), 
        });
      } else {
        
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  fetchUserReleases();
}, []);



const renderPickerModal = () => (
  <Modal isOpen={showPickerModal} onRequestClose={() => setShowPickerModal(false)}>
    <div style={styles.modalView}>
      {albumMode ? (
        userReleases.albums.map((album, index) => {
          const key = album.contentId || `album-${index}`;
          return (
            <div key={key} onClick={() => handleReleaseSelection(album.contentId, true)}>
              <p style={styles.modalText}>{album.albumName}</p>
            </div>
          );
        })
      ) : (
        userReleases.singles.map((single, index) => {
          const key = single.contentId || `single-${index}`;
          return (
            <div key={key} onClick={() => handleReleaseSelection(single.contentId, false)}>
              <p style={styles.modalText}>{single.SongName}</p>
            </div>
          );
        })
      )}
      <button onClick={() => setShowPickerModal(false)}>Close</button>
    </div>
  </Modal>
);




const categoriesList = [
  { label: "Podcasts", value: "podcasts" },
  { label: "Behind-the-Scenes", value: "behind_the_scenes" },
  { label: "Interviews/Q&A", value: "interviews" },
  { label: "Music Gear", value: "musicgear" },
  { label: "Comedy/Sketch", value: "comedy_sketch" },
  { label: "Gaming", value: "gameplays" },
  { label: "General Entertainment", value: "general_entertainment" },
  { label: "Courses/Tutorials", value: "tutorials" },
  { label: "News", value: "news" },
  { label: "Vlogs", value: "vlogs" },
  { label: "Product Reviews", value: "product_reviews" },
  { label: "Unboxings", value: "unboxings" },
  { label: "Short Films", value: "short_films" },
  { label: "Sports", value: "sports_highlights" },
  { label: "Cooking/Food", value: "cooking_food" },
  { label: "Travel", value: "travel" },
  { label: "Health/Fitness", value: "fitness_health" },
  { label: "Animation", value: "animation" },
  { label: "DIY/Crafts", value: "diy_crafts" },
  { label: "Fashion & Beauty", value: "beauty_fashion" },
  { label: "Technology/Gadgets", value: "technology_gadgets" },
  { label: "ASMR", value: "asmr" },
  { label: "Motivational/Inspirational", value: "motivational_inspirational" },
  { label: "Pranks", value: "pranks" },
  { label: "Nature/Wildlife", value: "nature_wildlife" },
  { label: "Storytelling", value: "storytelling" },
  { label: "Pets/Animals", value: "pets_animals" }
];




const categoryFieldsConfig = {
  podcasts: ['title', 'episode Number', 'description', 'tags'],
  interviews: ['title', 'description', 'guest', 'interviewer', 'tags',],
  vlogs: ['title', 'description', 'tags'],
  musicgear: ['title', 'description', 'tags'],
  tutorials: ['title', 'description', 'difficulty Level', 'tags'],
  product_reviews: ['title', 'description', 'tags',],
  unboxings: ['title', 'description', 'product Name', 'tags'],
  gameplays: ['title', 'description', 'platform', 'commentary', 'tags',],
  short_films: ['title', 'description', 'genre', 'ageGroup', 'tags'],
  behind_the_scenes: ['title', 'description', 'tags',],
  q_and_a: ['title', 'description', 'tags'],
  general_entertainment: ['title', 'description', 'ageGroup', 'tags', ],
  sports_highlights: ['title', 'description', 'sportType', 'tags'],
  cooking_food: ['title', 'description', 'tags'],
  travel: ['title', 'description', 'tags'],
  fitness_health: ['title', 'description', 'difficulty Level', 'tags'],
  news: ['title', 'description', 'reporter', 'tags',],
  comedy_sketch: ['title', 'description', 'actors', 'tags'],
  animation: ['title', 'description', 'Genre', 'ageGroup', 'tags'],
  diy_crafts: ['title', 'description', 'difficulty Level', 'tags'],
  beauty_fashion: [
    'title', 'description', 
    'tags',
  ],
  technology_gadgets: [
    'title', 'description',
    'tags'
  ],
  asmr: ['title', 'description', 'triggerTypes', 'tags' ],
  motivational_inspirational: ['title', 'description', 'tags'],
  pranks: ['title', 'description', 'tags',],
  nature_wildlife: ['title', 'description', 'tags'],
  storytelling: ['title', 'description', 'ageGroup', 'tags',],
  pets_animals: ['title', 'description', 'tags',]
};



const [selectedCategory, setSelectedCategory] = useState(null);
const [dynamicFields, setDynamicFields] = useState({});




useEffect(() => {
  if (selectedCategory && categoryFieldsConfig[selectedCategory]) {
    const initialFields = {};
    categoryFieldsConfig[selectedCategory].forEach(field => {
      initialFields[field] = '';
    });
    setDynamicFields(initialFields);
  } else {
    setDynamicFields({});
  }
}, [selectedCategory]);

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};



const renderDynamicFields = () => {
  if (!selectedCategory) return null;

  const fields = categoryFieldsConfig[selectedCategory];
  if (!fields) return null;

  return fields.map((field) => {
    if (multiPersonFields.includes(field)) {
      let query, setQuery, searching, results, addUser, removeUser, selectUserHandler;

      switch (field) {
        case 'guest':
          query = searchQueryGuest;
          setQuery = setSearchQueryGuest;
          searching = isSearchingGuest;
          results = searchResultsGuest;
          addUser = handleAddGuest;
          removeUser = handleRemoveGuest;
          selectUserHandler = selectTrackGuest;
          break;
        case 'actors':
          query = searchQueryGuest;
          setQuery = setSearchQueryGuest;
          searching = isSearchingGuest;
          results = searchResultsGuest;
          addUser = handleAddGuest;
          removeUser = handleRemoveGuest;
          selectUserHandler = selectTrackGuest;
          break;
          case 'interviewee':
            query = searchQueryInterviewee;
            setQuery = setSearchQueryInterviewee;
            searching = isSearchingInterviewee;
            results = searchResultsInterviewee;
            addUser = handleAddInterviewee;
            removeUser = handleRemoveInterviewee;
            selectUserHandler = selectTrackInterviewee;
            break;
        // Add more cases for other multi-person fields
        default:
          return null;
      }

      return (
        <div key={field} style={{ marginBottom: '20px' }}>
          <p style={styles.subtitle2}>{capitalizeFirstLetter(field)}</p>

          {/* Display Added Users */}
          <div style={styles.list}>
            {currentTrackDetails[field]?.map((user, index) => (
              <div key={user.id} style={styles.listItem}>
                <p style={styles.tagText}>{user.tag}</p>
                <button onClick={() => removeUser(index)} style={styles.removeButton}>Remove</button>
              </div>
            ))}
          </div>

          {/* Display Search Results */}
          {searching && results.map((user, index) => (
            <button key={index} onClick={() => selectUserHandler(user)} style={styles.userItem}>
              {`@${user.username} (${user.name})`}
            </button>
          ))}

          {/* Input and Add Button */}
          <div style={styles.inputContainer}>
            <input
              type="text"
              style={styles.input}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={`${capitalizeFirstLetter(field)}'s Name or @username`}
              maxLength={50}
            />
            <button onClick={addUser} style={styles.customButtonStyle}>Add</button>
          </div>
        </div>
      );
    } else {
      // Handle Single-Person or Simple Text Fields
      switch (field) {
        case 'title':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Title</p>
              <input
                type="text"
                style={styles.input}
                value={currentTrackDetails[field] || ''}
                onChange={(e) => setCurrentTrackDetails({ ...currentTrackDetails, [field]: e.target.value })}

                placeholder="Enter Title"
                maxLength={100}
              />
            </div>
          );
        case 'description':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Description</p>
              <textarea
                style={{ ...styles.input, height: '100px' }}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Description"
                rows={4}
              />
            </div>
          );
        case 'platform':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Platform</p>
              <select
                style={styles.select}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
              >
                <option value="" disabled>Select Platform...</option>
                <option value="multi_platform">Multi-Platform</option>
                <option value="pc">PC</option>
                <option value="xbox_one">Xbox One</option>
                <option value="xbox_series_x_s">Xbox Series X|S</option>
                <option value="playstation_4">PlayStation 4</option>
                <option value="playstation_5">PlayStation 5</option>
                <option value="nintendo_switch">Nintendo Switch</option>
                <option value="mobile_ios">Mobile - iOS</option>
                <option value="mobile_android">Mobile - Android</option>
                <option value="mac">Mac</option>
                <option value="linux">Linux</option>
                <option value="steam_deck">Steam Deck</option>
                <option value="vr_oculus">VR - Oculus</option>
                <option value="vr_htc_vive">VR - HTC Vive</option>
              </select>
            </div>
          );
        case 'difficulty Level':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Difficulty Level</p>
              <select
                style={styles.select}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
              >
                <option value="" disabled>Select Difficulty Level...</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>
          );
          case 'ageGroup':
            return (
              <div key={field} style={styles.inputContainer}>
                <p style={styles.subtitle2}>Age Group</p>
                <input
                  type="text"
                  style={styles.input}
                  value={dynamicFields[field] || ''}
                  onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                  placeholder="Age Group (e.g., Kids, Teens, Adults, All Ages)"
                  maxLength={50}
                />
              </div>
            );          
        case 'tags':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Tags</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Tags"
              />
            </div>
          );
        case 'product Name':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Product Name</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Product Name"
                maxLength={100}
              />
            </div>
          );
          case 'commentary':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Commentary</p>
      <select
        style={styles.select}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
      >
        <option value="" disabled>Select Commentary Option...</option>
        <option value="with_commentary">With Commentary</option>
        <option value="no_commentary">No Commentary</option>
      </select>
    </div>
  );

        case 'sportType':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Sport Type</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Sport Type (Football, Baseball, etc)"
              />
            </div>
          );
        case 'reporter':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Reporter</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Reporter's Name"
                maxLength={100}
              />
            </div>
          );
        case 'workoutType':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Workout Type</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Workout Type"
              />
            </div>
          );
        case 'materials':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Materials</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Materials"
              />
            </div>
          );
          case 'episode Number':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Episode Number</p>
      <input
        type="number"
        style={styles.input}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
        placeholder="Enter Episode Number"
        min="1"
      />
    </div>
  );

// Case for interviewer
case 'interviewer':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Interviewer</p>
      <input
        type="text"
        style={styles.input}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
        placeholder="Enter Interviewer's Name"
        maxLength={100}
      />
    </div>
  );

// Case for category
case 'categoryGaming':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Category</p>
      <select
        style={styles.select}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
      >
        <option value="" disabled>Select Category...</option>
        <option value="speedrun">Speedrun</option>
        <option value="casualgameplay">Casual Gameplay</option>
        <option value="review">Review</option>
        <option value="walkthrough">Walkthrough</option>
        <option value="how_to">How-To</option>
        <option value="first_play">First Play</option>
        <option value="challenge">Challenge</option>
        <option value="full_completion">100% Completion</option>
      </select>
    </div>
  );

// Case for director
case 'director':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Director</p>
      <input
        type="text"
        style={styles.input}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
        placeholder="Enter Director's Name"
        maxLength={100}
      />
    </div>
  );

// Case for genre
case 'genre':
  return (
    <div key={field} style={styles.inputContainer}>
      <p style={styles.subtitle2}>Genre</p>
      <select
        style={styles.select}
        value={dynamicFields[field] || ''}
        onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
      >
        <option value="" disabled>Select Genre...</option>
        <option value="action">Action</option>
        <option value="adventure">Adventure</option>
        <option value="animation">Animation</option>
        <option value="biography">Biography</option>
        <option value="comedy">Comedy</option>
        <option value="crime">Crime</option>
        <option value="dark_comedy">Dark Comedy</option>
        <option value="documentary">Documentary</option>
        <option value="drama">Drama</option>
        <option value="experimental">Experimental</option>
        <option value="family">Family</option>
        <option value="fantasy">Fantasy</option>
        <option value="fantasy_adventure">Fantasy Adventure</option>
        <option value="historical">Historical</option>
        <option value="horror">Horror</option>
        <option value="musical">Musical</option>
        <option value="mystery">Mystery</option>
        <option value="noir">Noir</option>
        <option value="psychological_thriller">Psychological Thriller</option>
        <option value="romance">Romance</option>
        <option value="sci_fi">Sci-Fi</option>
        <option value="silent_film">Silent Film</option>
        <option value="sports">Sports</option>
        <option value="superhero">Superhero</option>
        <option value="thriller">Thriller</option>
        <option value="war">War</option>
        <option value="western">Western</option>
      </select>
    </div>
  );

        case 'triggerTypes':
          return (
            <div key={field} style={styles.inputContainer}>
              <p style={styles.subtitle2}>Trigger Types</p>
              <input
                type="text"
                style={styles.input}
                value={dynamicFields[field] || ''}
                onChange={(e) => setDynamicFields({ ...dynamicFields, [field]: e.target.value })}
                placeholder="Enter Trigger Types (e.g., Whispering, Tapping)"
              />
            </div>
          );
        // Add more cases for other specific fields as needed
        default:
          return null;
      }
    }
  });
};







const pickTrackThumbnail = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const uri = reader.result;
      setTracks((currentTracks) =>
        currentTracks.map((track) =>
          track.trackNumber === selectedTrackForEdit.trackNumber ? { ...track, artworkURL: uri } : track
        )
      );
      setEditingLinkedTrack(false);
    };
    reader.readAsDataURL(file);
  }
};


const handleTrackSelection = (selectedTrack) => {
  setSelectedTrackForEdit(selectedTrack);
  setEditingLinkedTrack(true);
};



const handleReleaseSelection = (contentId, isAlbum) => {
  setSelectedReleaseId(contentId);
  setShowPickerModal(false);

  const selectedRelease = isAlbum ? userReleases.albums.find(album => album.contentId === contentId) : userReleases.singles.find(single => single.contentId === contentId);

  if (isAlbum) {
    const adjustedTracks = selectedRelease.tracks.map((song, index) => ({
      trackNumber: song.trackNumber || index + 1,
      title: song.SongName,
      artistName: { id: song.artistId, tag: song.ArtistName },
      artworkURL: song.ArtworkURL,
      songURL: song.SongURL,
      genres: song.genres,
      tags: song.tags,
      artistFeatures: song.artistFeatures,
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      lyrics: song.lyrics,
    })).sort((a, b) => a.trackNumber - b.trackNumber);

    setAlbumDetails({
      ...selectedRelease,
      isLinked: true,
    });

    setTracks(adjustedTracks);
  } else {

  }
};







const pickerSelectStyles = {
  input: {
    color: 'black',
    fontFamily: 'brhendrix',
    fontSize: `22px`,
  },
  placeholder: {
    color: 'black',
    fontFamily: 'brhendrix',
  },
};



const handleAddHost = () => {
  const input = searchQueryHost.trim();

  if (currentTrackDetails.hosts.length >= 5) {
    alert("Limit reached", "You can only add up to 5 hosts.");
    return;
  }

  if (input) {
    const newHost = {
      tag: input.startsWith('@') ? input : `@${input}`,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setCurrentTrackDetails(prevState => ({
      ...prevState,
      host: [...prevState.hosts, newHost]
    }));
  } else {
    alert("Invalid Input", "Please enter a host's name or @username.");
  }

  setSearchQueryHost('');
};

const handleRemoveHost = (index) => {
  setCurrentTrackDetails(prevState => ({
    ...prevState,
    host: prevState.hosts.filter((_, i) => i !== index)
  }));
};

const selectTrackHost = (user) => {
  if (currentTrackDetails.hosts.length >= 5) {
    alert("Limit reached", "You can only add up to 5 hosts.");
    return;
  }

  const newHost = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    host: [...prevState.hosts, newHost]
  }));

  setSearchQueryHost('');
  setIsSearchingHost(false);
};

const handleAddGuest = () => {
  const input = searchQueryGuest.trim();
  const isComedySketch = selectedCategory === "comedy_sketch";
  const key = isComedySketch ? 'actors' : 'guests';

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails[key]?.some((g) => g.tag === input)) {
      setCurrentTrackDetails((prevDetails) => ({
        ...prevDetails,
        [key]: [...(prevDetails[key] || []), { tag: input, id: Date.now().toString() }],
      }));
    } else {
      alert("Duplicate Entry", `This ${key.slice(0, -1)} is already added.`);
    }
  }

  setSearchQueryGuest('');
};

const handleRemoveGuest = (index) => {
  const isComedySketch = selectedCategory === "comedy_sketch";
  const key = isComedySketch ? 'actors' : 'guests';

  setCurrentTrackDetails((prevDetails) => ({
    ...prevDetails,
    [key]: prevDetails[key]?.filter((_, i) => i !== index),
  }));
};

const selectTrackGuest = (user) => {
  const isComedySketch = selectedCategory === "comedy_sketch";
  const key = isComedySketch ? 'actors' : 'guests';

  if (currentTrackDetails[key]?.length >= 20) {
    alert("Limit reached", `You can only add up to 20 ${key}.`);
    return;
  }

  const newEntry = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id,
  };

  setCurrentTrackDetails((prevState) => ({
    ...prevState,
    [key]: [...(prevState[key] || []), newEntry],
  }));

  setSearchQueryGuest('');
  setIsSearchingGuest(false);
};





const handleAddInterviewee = () => {
  const input = searchQueryInterviewee.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.interviewees.some(i => i.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        interviewee: [...prevDetails.interviewees, { tag: input, id: Date.now().toString() }]
      }));
    } else {
      alert("Duplicate Entry", "This interviewee is already added.");
    }
  }

  setSearchQueryInterviewee('');
};

const handleRemoveInterviewee = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    interviewee: prevDetails.interviewees.filter((_, i) => i !== index)
  }));
};


const selectTrackInterviewee = (user) => {
  if (currentTrackDetails.interviewees.length >= 5) {
    alert("Limit reached", "You can only add up to 5 interviewees for a track.");
    return;
  }

  const newInterviewee = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    interviewee: [...prevState.interviewees, newInterviewee]
  }));

  setSearchQueryInterviewee('');
  setIsSearchingInterviewee(false);
};




const handleAddParticipants = () => {
  const input = searchQueryParticipants.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.participants.some(p => p.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        participants: [...prevDetails.participants, { tag: input, id: Date.now().toString() }]
      }));
    } else {
      alert("Duplicate Entry", "This participant is already added.");
    }
  }

  setSearchQueryParticipants('');
};

const handleRemoveParticipants = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    participants: prevDetails.participants.filter((_, i) => i !== index)
  }));
};

const selectTrackParticipants = (user) => {
  if (currentTrackDetails.participants.length >= 5) {
    alert("Limit reached", "You can only add up to 5 participants for a track.");
    return;
  }

  const newParticipant = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    participants: [...prevState.participants, newParticipant]
  }));

  setSearchQueryParticipants('');
  setIsSearchingParticipants(false);
};




const handleAddModerator = () => {
  const input = searchQueryModerator.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.moderators.some(m => m.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        moderator: [...prevDetails.moderators, { tag: input, id: Date.now().toString() }]
      }));
    } else {
      alert("Duplicate Entry", "This moderator is already added.");
    }
  }

  setSearchQueryModerator('');
};

const handleRemoveModerator = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    moderator: prevDetails.moderators.filter((_, i) => i !== index)
  }));
};


const selectTrackModerator = (user) => {
  if (currentTrackDetails.moderators.length >= 5) {
    alert("Limit reached", "You can only add up to 5 moderators for a track.");
    return;
  }

  const newModerator = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    moderator: [...prevState.moderators, newModerator]
  }));

  setSearchQueryModerator('');
  setIsSearchingModerator(false);
};

  const storage = getStorage();
  const functions = getFunctions();
  const releaseMusicVideo = httpsCallable(functions, 'releaseMusicVideo');

  const pickVideo = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        songURL: uri,
        videoDisplayName: file.name
      }));
    };
    input.click();
  };




  const pickVideoLinked = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const videoURL = URL.createObjectURL(file);
  
      setTracks((prevTracks) =>
        prevTracks.map((track) =>
          track.trackNumber === selectedTrackForEdit.trackNumber
            ? { ...track, videoDisplayName: fileName, songURL: videoURL }
            : track
        )
      );
  
      setEditingLinkedTrack(false);
      setSelectedTrackForEdit(null);
    }
  };

  const inputVideoRef = useRef(null);


  const toggleMode = (mode) => {
    setAlbumMode(mode === 'album');
  };

  const pickArtwork = async (event) => {
    const file = event.target.files[0];
    if (file) {
      

      const fileUrl = URL.createObjectURL(file);
      setAlbumDetails({ ...albumDetails, artworkFile: file, artworkURL: fileUrl });
      
    }
  };




  const uploadVideoThumbnail = async (file, folderName = 'video_thumbnails/') => {
    if (!file) {
      console.error('No file provided for upload');
      return;
    }
  
    
    
  
    const fileRef = ref(storage, `${folderName}${new Date().toISOString()}`);
    const metadata = {
      contentType: file.type || 'image/png', // Use the correct MIME type
    };
  
    try {
      
      
  
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
          },
          (error) => {
            console.error('Error during photo upload:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            resolve(downloadURL);
          }
        );
      });
    } catch (error) {
      console.error('Error during photo upload:', error);
      throw error;
    }
  };
  
  







const addGenre = () => {
  if (selectedGenre) {
    if (!albumDetails.genres.includes(selectedGenre) && albumDetails.genres.length < 1) {
      setAlbumDetails(prevState => ({ ...prevState, genres: [...prevState.genres, selectedGenre] }));
    }
  }
};

  const addTag = () => {
    if (selectedTag && !albumDetails.tags.includes(selectedTag) && albumDetails.tags.length < 50) {
      setAlbumDetails(prevState => ({ ...prevState, tags: [...prevState.tags, selectedTag] }));
    }
  };

  const removeGenre = (genre) => {
    setAlbumDetails(prevState => ({ ...prevState, genres: prevState.genres.filter(g => g !== genre) }));
  };

  const removeTag = (tag) => {
    setAlbumDetails(prevState => ({ ...prevState, tags: prevState.tags.filter(t => t !== tag) }));
  };

const addProducer = () => {
  const input = searchQueryProducers.trim();

  if (albumDetails.producers.length >= 5) {
    return;
  }

  if (input) {
    const newProducer = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      producers: [...prevState.producers, newProducer]
    }));
  } else {
  }

  setSearchQueryProducers('');
};


const removeProducer = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    producers: prevState.producers.filter(producer => producer.id !== id)
  }));
};


const addSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (albumDetails.songwriters.length >= 5) {
    return;
  }

  if (input) {
    const newSongwriter = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      songwriters: [...prevState.songwriters, newSongwriter]
    }));
  } else {
  }

  setSearchQuerySongwriters('');
};

const removeSongwriter = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: prevState.songwriters.filter(songwriter => songwriter.id !== id)
  }));
};




const addArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (albumDetails.artistFeatures.length >= 5) {
    return;
  }

  if (input) {
    const newArtistFeature = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      artistFeatures: [...prevState.artistFeatures, newArtistFeature]
    }));
  } else {
  }

  setSearchQueryArtistFeatures('');
};

    const removeArtistFeature = (id) => {
      setAlbumDetails(prevState => ({
        ...prevState,
        artistFeatures: prevState.artistFeatures.filter(artistFeature => artistFeature.id !== id)
      }));
    };

const removeArtistName = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: null
  }));
};



const addRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (albumDetails.recordLabels.length >= 3) {
    return;
  }

  if (input) {
    const newRecordLabel = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      recordLabels: [...prevState.recordLabels, newRecordLabel]
    }));
  } else {
  }

  setSearchQueryRecordLabels('');
};

const removeRecordLabel = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: prevState.recordLabels.filter(recordLabel => recordLabel.id !== id)
  }));
};



const newTrackTemplate = {
  title: '',
  artworkURL: '',
  songURL: '',
  producers: [],
  songwriters: [],
  artistFeatures: [],
  recordLabels: [],
  lyrics: ''

};



const handleAddTrack = () => {
  if (currentForm === 'Add Track') {
    const newTrackNumber = tracks.length + 1;
    const newTrack = { ...currentTrackDetails, trackNumber: newTrackNumber };
    setTracks([...tracks, newTrack]);

    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: newTrackNumber + 1,
    });
  } else {
    const updatedTracks = tracks.map(track => {
      if (track.trackNumber === currentTrackDetails.trackNumber) {
        return currentTrackDetails;
      }
      return track;
    });

    setTracks(updatedTracks);
    setCurrentForm('Add Track');
    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: tracks.length + 1,
    });
  }
};





useEffect(() => {
  
}, [currentTrackDetails]);

useEffect(() => {
  setTitleInput(currentTrackDetails.title);
}, [currentTrackDetails.title]);

const handleTitleChange = (event) => {
  const text = event.target.value; // Get the value from the event
  setTitleInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, title: text }));
};

useEffect(() => {
  setLyricsInput(currentTrackDetails.lyrics);
}, [currentTrackDetails.lyrics]);

const handleLyricsChange = (text) => {
  setLyricsInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, lyrics: text }));
};



const formatArtistWithFeatures = (artistName, artistFeatures) => {
  if (artistFeatures && artistFeatures.length > 0) {
    const featureNames = artistFeatures.map(feature => feature.tag);
    return `${artistName} ft. ${featureNames.join(', ')}`;
  } else {
    return artistName;
  }
};



const renderTrackModules = () => {
  if (!currentTrackDetails) return null; // Ensure the details are available for the SPNCast video

  return (
    <div
      key={currentTrackDetails.contentId}
      onClick={() => {
        setCurrentForm(`Edit SPNCast Video`);
        setEditingAlbumDetails(false);
        setFormMode('edit');
      }}
      style={styles.albumInfoContainer}
    >
      <img
        src={currentTrackDetails.artworkURL}
        style={styles.imagePreview}
        alt="Track Artwork"
      />
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Title:\n${currentTrackDetails.title}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Artist:\n${formatArtistWithFeatures(currentTrackDetails.artistName.tag, currentTrackDetails.artistFeatures?.map(f => f.tag).join(', '))}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Video File:\n${currentTrackDetails.videoDisplayName || 'No file selected'}`}
      </p>

      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Category:\n${selectedCategory}`}
      </p>

      {['guests', 'participants', 'moderators', 'hosts'].map((fieldKey) => {
        if (currentTrackDetails[fieldKey] && currentTrackDetails[fieldKey].length > 0) {
          return (
            <p key={fieldKey} style={{ ...styles.albumInfoText, color: 'white' }}>
              {`${capitalizeFirstLetter(fieldKey)}:\n${currentTrackDetails[fieldKey].map(item => item.tag).join(', ')}`}
            </p>
          );
        }
        return null;
      })}

      {Object.keys(dynamicFields).map((fieldKey) => {
        if (dynamicFields[fieldKey]) {
          return (
            <p key={fieldKey} style={{ ...styles.albumInfoText, color: 'white' }}>
              {`${capitalizeFirstLetter(fieldKey)}:\n${dynamicFields[fieldKey]}`}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
};





const [isReviewing, setIsReviewing] = useState(false);



const renderTrackModulesLinked = () => {
  if (tracks.length === 0) return null;

  return tracks.map((track) => (
    <div
      key={track.trackNumber}
      onClick={() => handleTrackSelection(track)}
      style={styles.albumInfoContainer}
    >
      <img
        src={track.artworkURL || albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Track Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Track ${track.trackNumber}:\n${track.title}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${track.artistName.tag}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Video File:\n${track.videoDisplayName || 'No file selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${track.producers.map(p => p.tag).join(', ')}`} 
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${track.songwriters.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${track.recordLabels.map(r => r.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${track.genres?.join(', ')}`}
      </p>
    </div>
  ));
};






const handleAlbumDetailsSubmit = () => {
  setFormMode('add');
  setAlbumDetailsVisible(true);
  setAddingSongs(true);

  setCurrentForm('Add Track');

};



const handleSaveTrackChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


  setFormMode('add');
  setCurrentForm('Add Track');
};




const handleSaveSingleChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


};



const inputFileRef = useRef(null);

const removeTrack = (trackNumber) => {
  const updatedTracks = [...tracks];

  const indexToRemove = updatedTracks.findIndex(t => t.trackNumber === trackNumber);

  if (indexToRemove !== -1) {
    updatedTracks.splice(indexToRemove, 1);

    updatedTracks.forEach((track, index) => {
      if (track.trackNumber > trackNumber) {
        updatedTracks[index].trackNumber -= 1;
      }
    });

    setTracks(updatedTracks);

    if (currentTrackNumber === trackNumber) {
      setCurrentTrackNumber(updatedTracks.length > 0 ? Math.min(trackNumber, updatedTracks.length) : 1);
    }
  }
};

async function testUploadSong() {
  try {
    const firebasePath = `single_songs/${currentTrackDetails.title.replace(/[^a-zA-Z0-9]/g, '_')}`;
    const uploadResult = await uploadSongFileToFirebase(currentTrackDetails.songURL, firebasePath);
    
  } catch (error) {
    console.error('Test upload failed:', error);
  }
}



async function uploadSongFileToFirebase(songFile, firebasePath) {
  try {
    

    
    const reader = new FileReader();

    reader.onloadend = async (event) => {
      const arrayBuffer = event.target.result;

      
      const blob = new Blob([arrayBuffer], { type: songFile.type });

      
      const storageRef = ref(storage, firebasePath);
      

      
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // You can add progress handling here if needed
        },
        (error) => {
          console.error('Error during upload:', error);
          throw error;
        },
        async () => {
          

          
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          

          return downloadURL;
        }
      );
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      throw error;
    };

    reader.readAsArrayBuffer(songFile);
  } catch (error) {
    console.error('Error uploading song file:', error);
    throw error;
  }
}

const [uploadProgress, setUploadProgress] = useState(0);

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const uploadVideoFile = async (uri, fileName, retries = 5, delayTime = 3000) => {
  console.log(`Starting upload for file: ${fileName}`);
  const storage = getStorage();
  const storageRef = ref(storage, `videos/${fileName}`);
  
  const attemptUpload = async (retryCount) => {
    console.log(`Attempting upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
    try {
      console.log(`Fetching video file from URI: ${uri}`);
      const response = await fetch(uri);
      const blob = await response.blob();
      console.log(`Fetched video file: ${fileName}, Blob size: ${blob.size} bytes`);
  
      const uploadTask = uploadBytesResumable(storageRef, blob);
      console.log(`Upload task created for file: ${fileName}`);
  
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload progress for ${fileName}: ${progress.toFixed(2)}%`);
            setUploadProgress(progress); // Update the progress state
          },
          (error) => {
            console.error(`Upload error for file: ${fileName}`, error);

            // Check for additional details in the error payload
            if (error.serverResponse) {
              try {
                const errorPayload = JSON.parse(error.serverResponse);
                console.error(`Server response for file ${fileName}:`, errorPayload);
              } catch (e) {
                console.error(`Error parsing server response for file ${fileName}:`, e);
              }
            }

            reject(error);
          },
          () => {
            console.log(`Upload successful for file: ${fileName}`);
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(`Download URL for file ${fileName}: ${downloadURL}`);
              resolve(downloadURL);
            });
          }
        );
      });
  
      if (blob.close) {
        console.log(`Closing blob for file: ${fileName}`);
        blob.close();
      }

      return await getDownloadURL(uploadTask.snapshot.ref);
    } catch (error) {
      console.error(`Error during upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
      if (retryCount > 0) {
        console.log(`Retrying upload for file: ${fileName} (${retries - retryCount + 1}/3) after delay of ${delayTime}ms...`);
        await delay(delayTime);
        return attemptUpload(retryCount - 1);
      } else {
        console.error(`Upload failed after 3 retries for file: ${fileName}`);
        alert("Low internet connection, please try again");
        throw error; // Re-throw to be caught by caller
      }
    }
  };
  
  const result = await attemptUpload(retries);
  console.log(`Final download URL for file ${fileName}: ${result}`);
  return result;
};




const prepareThumbnail = async (uri) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = uri;
    
    img.onload = () => {
      const { width, height } = img;

      // Calculate target dimensions for 16:9 aspect ratio
      let targetWidth, targetHeight;
      if (width / height > 16 / 9) {
        // Image is wider than 16:9
        targetHeight = height;
        targetWidth = targetHeight * (16 / 9);
      } else {
        // Image is narrower than 16:9 or equal
        targetWidth = width;
        targetHeight = targetWidth / (16 / 9);
      }

      // Calculate position to start cropping
      const cropX = (width - targetWidth) / 2;
      const cropY = (height - targetHeight) / 2;

      // Create a canvas to crop the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      ctx.drawImage(img, cropX, cropY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight);

      // Convert canvas to a data URL
      canvas.toDataURL('image/jpeg', (dataUrl) => {
        resolve(dataUrl);
      });
    };

    img.onerror = reject;
  });
};

const [alertMessage, setAlertMessage] = useState('');

const processVideoInCloudRun = (spnCastVideoData, idToken) => {
  const cloudRunURL = 'https://video-process-638251230937.us-east1.run.app'; // Your Cloud Run URL

  try {
    fetch(cloudRunURL + '/process-video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`, // Pass ID token here
      },
      body: JSON.stringify(spnCastVideoData), // Send the full SPNCast data
    })
    .then(response => {
      if (!response.ok) {
        console.error('Failed to process video in Cloud Run.');
      } else {
        console.log('Cloud Run successfully processing the video in the background.');
      }
    })
    .catch(error => {
      console.error('Error while sending request to Cloud Run:', error);
    });
 
  } catch (error) {
    console.error('Unexpected error while processing video in Cloud Run:', error);
  }
};


const handleSubmit = async () => {
  try {
    console.log('handleSubmit initiated');
    setCurrentlyReleasing(true);

    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken(true);
    console.log('Fetched ID token:', idToken);

    const userId = getCurrentUserId();
    console.log('Current user ID:', userId);

    const extractInfoFromTag = (tag) => {
      const parts = tag.split(' - ');
      console.log(`Extracting info from tag: ${tag}`, parts);
      return {
        username: parts.length > 1 ? parts[0] : null,
        artistName: parts.length > 1 ? parts[1] : tag
      };
    };

    if (albumMode) {
      console.log('Album mode enabled');

      const sanitizedAlbumName = albumDetails.albumName.replace(/[^a-zA-Z0-9]/g, '_');
      console.log('Sanitized album name:', sanitizedAlbumName);

      const albumThumbnailURL = await uploadVideoThumbnail(albumDetails.artworkFile);
      console.log('Uploaded album thumbnail URL:', albumThumbnailURL);

      const albumData = {
        username: extractInfoFromTag(albumDetails.artistName.tag).username,
        isAlbum: true,
        isVideo: true,
        userId,
        ArtistName: extractInfoFromTag(albumDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(albumDetails.artistName.tag).artistName.toLowerCase(),
        artistId: albumDetails.artistName.id,
        albumName: albumDetails.albumName,
        VideoThumbnailURL: albumThumbnailURL,
        genres: albumDetails.genres,
        artistFeatures: albumDetails.artistFeatures,
        producers: albumDetails.producers,
        songwriters: albumDetails.songwriters,
        recordLabels: albumDetails.recordLabels,
        videos: tracks.map(track => ({
          title: track.title,
          VideoURL: track.songURL,
          VideoThumbnailURL: track.artworkURL
        }))
      };

      console.log('Constructed album data:', albumData);

      // Handle album data upload or further processing here...

    } else {
      console.log('Single track mode enabled');

      if (!currentTrackDetails.title || !currentTrackDetails.artworkURL) {
        console.warn('Missing title or video thumbnail');
        setAlertMessage('The title field and video thumbnail are required.');
        setCurrentlyReleasing(false);
        return;
      }

      const filteredCollaborators = collaborators.filter((collab) => collab.name && collab.percentage);
      console.log('Filtered collaborators:', filteredCollaborators);

      const generateSPNCastData = () => {
        const spnCastData = {};
        for (let field in dynamicFields) {
          if (dynamicFields[field]) {
            spnCastData[field] = dynamicFields[field];
          }
        }
        console.log('Generated SPNCast dynamic fields:', spnCastData);
        return spnCastData;
      };

      const videoThumbnailURL = await uploadVideoThumbnail(currentTrackDetails.artworkFile);
      console.log('Uploaded video thumbnail URL:', videoThumbnailURL);

      const tempVideoURL = await uploadVideoFile(currentTrackDetails.songURL, currentTrackDetails.videoDisplayName);
      console.log('Uploaded temp video URL:', tempVideoURL);

      const spnCastVideoData = {
        username: extractInfoFromTag(currentTrackDetails.artistName.tag).username,
        isAlbum: false,
        isVideo: true,
        isSPNCast: true,
        userId,
        ArtistName: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName.toLowerCase(),
        artistId: currentTrackDetails.artistName.id,
        VideoName: currentTrackDetails.title,
        SongName: currentTrackDetails.title,
        songNameLower: currentTrackDetails.title.toLowerCase(),
        VideoURL: tempVideoURL, // Send the temp video URL initially
        VideoThumbnailURL: videoThumbnailURL,
        ArtworkURL: videoThumbnailURL,
        collaborators: filteredCollaborators,
        category: selectedCategory, // Include the category of the SPNCast video
        ...generateSPNCastData(), // Include dynamic fields for SPNCast
      };

      console.log('Constructed SPNCast video data:', spnCastVideoData);
      console.log('Sending video data to Cloud Run for processing...');

      const idToken = await user.getIdToken(true);
      console.log('Fetched ID token:', idToken);
      
      // Send to Cloud Run in background, without waiting
      processVideoInCloudRun(spnCastVideoData, idToken);

      console.log('SPNCast video data sent for background processing');

      alert('The SPNCast is now processing and will be released within the next hour.');
      setCurrentlyReleasing(false);
      setIsReviewing(false);
      resetForm();
    }
  } catch (error) {
    console.error('Error with the music release process:', error);
    setCurrentlyReleasing(false);
  }
};



function resetForm() {

  setAlbumDetails((prevState) => ({
    ...prevState, // Spread the previous state
    albumName: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: []
    // artistName is not reset here, preserving its current value
  }));

  setCurrentTrackDetails((prevState) => ({
    ...prevState, // Spread the previous state
    title: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: [],
    lyrics: ''
    // artistName is not reset here, preserving its current value
  }));

  // Assuming setTracks is a setter for track details, resetting it as before
  setTracks([]);
}







useEffect(() => {
  if (currentUser) {
    setAlbumDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`, 
        id: currentUser.uid, 
      },
    }));

    setCurrentTrackDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`, 
        id: currentUser.uid, 
      },
    }));
  }
}, [currentUser]);



    
const selectProducer = (user) => {
  if (albumDetails.producers.length >= 5) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};


const selectSongwriter = (user) => {
  if (albumDetails.producers.length >= 5) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};




const selectArtistFeature = (user) => {
  if (albumDetails.artistFeatures.length >= 5) {
    return;
  }

  const newArtistFeature = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistFeatures: [...prevState.artistFeatures, newArtistFeature]
  }));

  setSearchQueryArtistFeatures('');
  setIsSearchingArtistFeatures(false);
};

const selectArtistName = (user) => {
  if (albumDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};



const selectRecordLabel = (user) => {
  if (albumDetails.recordLabels.length >= 3) {
    return;
  }

  const newRecordLabel = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};



const getPickerItems = () => {
  const items = [{ label: 'Album Details', value: 'Album Details' }];
  tracks.forEach((track, index) => {
    const label = track.name || `Track ${index + 1}`;
    items.push({ label, value: label });
  });
  return items;
};



useEffect(() => {
  if (currentForm === 'Add Track') {
    setCurrentTrackDetails({
      title: '',
      artworkURL: albumDetails.artworkURL,
      artworkFile: albumDetails.artworkFile,
      songURL: '',
      genres: albumDetails.genres || [],
      tags: albumDetails.tags || [],
      producers: albumDetails.producers,
      artistFeatures: albumDetails.artistFeatures,
      songwriters: albumDetails.songwriters,
      recordLabels: albumDetails.recordLabels,
      lyrics: '',
      trackNumber: tracks.length + 1,
      artistName: albumDetails.artistName ? { 
        id: albumDetails.artistName.id, 
        tag: albumDetails.artistName.tag 
      } : null,
    });
  }
}, [currentForm, tracks.length, albumDetails]);





useEffect(() => {
  const search = async () => {
    if (searchQueryHost.startsWith("@") && searchQueryHost.length > 1) {
      setIsSearchingHost(true);
      const searchValue = searchQueryHost.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsHost(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsHost([]);
      }
    } else {
      setIsSearchingHost(false);
      setSearchResultsHost([]);
    }
  };

  if (searchQueryHost) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryHost]);

// Guest
useEffect(() => {
  const search = async () => {
    if (searchQueryGuest.startsWith("@") && searchQueryGuest.length > 1) {
      setIsSearchingGuest(true);
      const searchValue = searchQueryGuest.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsGuest(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsGuest([]);
      }
    } else {
      setIsSearchingGuest(false);
      setSearchResultsGuest([]);
    }
  };

  if (searchQueryGuest) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryGuest]);

// Interviewee
useEffect(() => {
  const search = async () => {
    if (searchQueryInterviewee.startsWith("@") && searchQueryInterviewee.length > 1) {
      setIsSearchingInterviewee(true);
      const searchValue = searchQueryInterviewee.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsInterviewee(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsInterviewee([]);
      }
    } else {
      setIsSearchingInterviewee(false);
      setSearchResultsInterviewee([]);
    }
  };

  if (searchQueryInterviewee) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryInterviewee]);

// Participants
useEffect(() => {
  const search = async () => {
    if (searchQueryParticipants.startsWith("@") && searchQueryParticipants.length > 1) {
      setIsSearchingParticipants(true);
      const searchValue = searchQueryParticipants.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsParticipants(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsParticipants([]);
      }
    } else {
      setIsSearchingParticipants(false);
      setSearchResultsParticipants([]);
    }
  };

  if (searchQueryParticipants) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryParticipants]);

// Moderator
useEffect(() => {
  const search = async () => {
    if (searchQueryModerator.startsWith("@") && searchQueryModerator.length > 1) {
      setIsSearchingModerator(true);
      const searchValue = searchQueryModerator.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsModerator(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsModerator([]);
      }
    } else {
      setIsSearchingModerator(false);
      setSearchResultsModerator([]);
    }
  };

  if (searchQueryModerator) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryModerator]);



    
useEffect(() => {
  const search = async () => {
    if (searchQueryProducers.startsWith("@") && searchQueryProducers.length > 1) {
      setIsSearchingProducers(true);
      const searchValue = searchQueryProducers.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsProducers(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsProducers([]);
      }
    } else {
      setIsSearchingProducers(false);
      setSearchResultsProducers([]);
    }
  };

  if (searchQueryProducers) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryProducers]);



    
useEffect(() => {
  const search = async () => {
    if (searchQuerySongwriters.startsWith("@") && searchQuerySongwriters.length > 1) {
      setIsSearchingSongwriters(true);
      const searchValue = searchQuerySongwriters.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target 'username_lower' for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsSongwriters(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsSongwriters([]);
      }
    } else {
      setIsSearchingSongwriters(false);
      setSearchResultsSongwriters([]);
    }
  };

  if (searchQuerySongwriters) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQuerySongwriters]);



const selectCollaborator = (user, index) => {
  setCollaborators(prevCollaborators => {
    return prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the selected collaborator with the userId
        return {
          ...collaborator,
          name: `@${user.username} (${user.name})`, // Format the selected collaborator's name
          userId: user.userId, // Add the userId of the selected collaborator
          searchQuery: '', // Clear the search query
          isSearching: false, // Stop the search
          searchResults: [], // Clear the search results
        };
      } else {
        // Ensure all other collaborators' search results are cleared
        return {
          ...collaborator,
          searchQuery: '',
          isSearching: false,
          searchResults: []
        };
      }
    });
  });

  // Optionally, clear any global search input and search results state
  setSearchQueryCollaborator('');
  setSearchResultsCollaborator([]);
  setIsSearchingCollaborator(false);
};


const [searchQueries, setSearchQueries] = useState([]);

const handleCollaboratorChange = (index, field, value) => {
  setCollaborators(prevCollaborators => {
    const updatedCollaborators = prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the collaborator being edited
        return {
          ...collaborator,
          [field]: value,
          isSearching: field === 'searchQuery' ? true : collaborator.isSearching,
        };
      } else if (field === 'searchQuery') {
        // Clear search results for other collaborators
        return {
          ...collaborator,
          searchQuery: '',
          searchResults: [],
          isSearching: false,
        };
      }
      return collaborator;
    });

    // Log the updated collaborators object to see its structure
    console.log('Updated collaborators:', updatedCollaborators);
    return updatedCollaborators;
  });

  if (field === 'searchQuery') {
    setSearchQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index] = value;
      return updatedQueries;
    });
  }
};




useEffect(() => {
  const searchCollaborators = async () => {
    const currentSearchQueryIndex = searchQueries.findIndex(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1);

    // Clear search results for all other collaborators before starting a new search
    setCollaborators(prevCollaborators => {
      return prevCollaborators.map((collaborator, idx) => {
        if (idx !== currentSearchQueryIndex) {
          return {
            ...collaborator,
            searchResults: [],
            isSearching: false,
          };
        }
        return collaborator;
      });
    });

    const searchPromises = searchQueries.map(async (searchQuery, index) => {
      if (searchQuery.startsWith("@") && searchQuery.length > 1) {
        const searchValue = searchQuery.substring(1).toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef,
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );

        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: users,
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });

        } catch (error) {
          console.error("Error searching users:", error);
          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: [],
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });
        }
      }
    });

    await Promise.all(searchPromises);
  };

  if (searchQueries.some(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1)) {
    searchCollaborators();
  }
}, [searchQueries]);




useEffect(() => {
  const search = async () => {
    if (searchQueryArtistFeatures.startsWith("@") && searchQueryArtistFeatures.length > 1) {
      setIsSearchingArtistFeatures(true);
      const searchValue = searchQueryArtistFeatures.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to use 'username_lower' field for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistFeatures(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistFeatures([]);
      }
    } else {
      setIsSearchingArtistFeatures(false);
      setSearchResultsArtistFeatures([]);
    }
  };

  if (searchQueryArtistFeatures) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryArtistFeatures]);

useEffect(() => {
  const search = async () => {
    // Check if the search query starts with "@" and has more than one character
    if (searchQueryArtistName.startsWith("@") && searchQueryArtistName.length > 1) {
      setIsSearchingArtistName(true);
      // Extract the search value, omitting the "@" symbol and converting to lowercase
      const searchValue = searchQueryArtistName.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Update the query to target the 'username_lower' field for a case-insensitive search
      // and limit the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistName(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistName([]);
      }
    } else {
      // Reset the search if the conditions aren't met
      setIsSearchingArtistName(false);
      setSearchResultsArtistName([]);
    }
  };

  // Debounce the search to reduce the frequency of Firestore queries
  if (searchQueryArtistName) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Wait 300ms after the user stops typing

    // Cleanup function to cancel the timeout when the component unmounts or the effect reruns
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryArtistName]);




useEffect(() => {
  const search = async () => {
    // Check if the query starts with "@" and has more than one character
    if (searchQueryRecordLabels.startsWith("@") && searchQueryRecordLabels.length > 1) {
      setIsSearchingRecordLabels(true);
      const searchValue = searchQueryRecordLabels.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target a lowercase field for case-insensitive search,
      // here I'm assuming 'username_lower' is the field you're interested in
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the results to improve efficiency
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsRecordLabels(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsRecordLabels([]);
      }
    } else {
      setIsSearchingRecordLabels(false);
      setSearchResultsRecordLabels([]);
    }
  };

  // Debounce the search to prevent excessive Firestore queries
  if (searchQueryRecordLabels) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // 300ms delay

    // Cleanup to cancel the timeout when the component unmounts or the input changes
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryRecordLabels]);


const renderSingleSongForm = () => {
  return renderTrackFormContent(currentTrackDetails);
};




const renderAlbumDetailsModule = () => {
  if (!albumDetailsVisible) return null;

  return (
    <div style={styles.albumInfoContainer}>
      <img
        src={albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Album Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Album Name:\n${albumDetails.albumName}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${albumDetails.artistName ? albumDetails.artistName.tag : 'Not selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Features:\n${albumDetails.artistFeatures.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${albumDetails.producers.map(producer => producer.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${albumDetails.songwriters.map(songwriter => songwriter.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${albumDetails.recordLabels.map(recordLabel => recordLabel.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${albumDetails.genres.join(', ')}`}
      </p>
    </div>
  );
};



const renderAlbumDetailsModuleLinked = () => {
  if (!albumDetailsVisible) return null;

  const features = albumDetails.artistFeatures?.map(f => f.tag).join(', ') || 'None';
  const producers = albumDetails.producers?.map(p => p.tag).join(', ') || 'None';
  const songwriters = albumDetails.songwriters?.map(s => s.tag).join(', ') || 'None';
  const recordLabels = albumDetails.recordLabels?.map(r => r.tag).join(', ') || 'None';
  const genres = albumDetails.genres?.join(', ') || 'None';
  const tags = albumDetails.tags?.join(', ') || 'None';

  return (
    <div style={styles.albumInfoContainer}>
      <img
        src={albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Album Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Album Name:\n${albumDetails.albumName}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${albumDetails.artistName?.tag || 'Not selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Features:\n${features}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${producers}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${songwriters}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${recordLabels}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${genres}`}
      </p>
    </div>
  );
};












const renderTrackForm = () => {
  if (currentForm === 'Add Track') {

    return renderTrackFormContent(currentTrackDetails);
  } else if (currentForm === 'Album Details') {
    return renderAlbumDetailsForm();
  } else {
    const trackNumber = parseInt(currentForm.split(' ')[2], 10);
    const trackToEdit = tracks.find(track => track.trackNumber === trackNumber);
    return renderTrackFormContent(trackToEdit);
  }
};


const renderAlbumDetailsForm = () => {
  return (
    <div style={styles.scrollViewInnerContainer}>
      <h3 style={styles.subtitle3}>Artist</h3>

      {/* Display Selected Artist Name */}
      {albumDetails.artistName && (
        <div style={styles.listItem}>
          <p style={styles.tagText}>{albumDetails.artistName.tag}</p>
        </div>
      )}

      <h4 style={styles.subtitle4}>Album Name</h4>
      <input
        type="text"
        style={styles.inputWider}
        value={albumDetails.albumName}
        onChange={(e) =>
          albumMode
            ? setAlbumDetails({ ...albumDetails, albumName: e.target.value })
            : setSongTitle(e.target.value)
        }
        placeholder="Album Name"
        maxLength={30}
      />
      <div style={styles.buttonContainer}>
        <input type="file" accept="image/*" onChange={pickArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
        <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Album Artwork</button>
        {albumDetails.artworkURL && (
          <img
            src={albumDetails.artworkURL}
            style={styles.imagePreview}
            alt="Artwork Preview"
          />
        )}
      </div>

      <h2 style={styles.subtitle2}>Genres</h2>

      {/* Display added genres */}
      <div style={styles.tagList}>
        {albumDetails.genres.map((genre, index) => (
          <div key={index} style={styles.tagItem}>
            <p style={styles.tagText}>{genre}</p>
            <button onClick={() => removeGenre(genre)} style={{ ...styles.removeButton, ...styles.whiteText }}>X</button>
          </div>
        ))}
      </div>

      <div style={styles.inputContainer}>
        <div style={styles.pickerContainer}>
          <select onChange={(e) => setSelectedGenre(e.target.value)} style={pickerSelectStyles}>
            <option value={null}>Select Genre...</option>
            {genresList.map((genre, index) => (
              <option key={index} value={genre.value}>{genre.label}</option>
            ))}
          </select>
        </div>
        <button onClick={addGenre} style={styles.customButtonStyle2}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Artist Features</h2>
      {isSearchingArtistFeatures &&
        searchResultsArtistFeatures.map((user, index) => (
          <button key={index} onClick={() => selectArtistFeature(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.artistFeatures.map((artistFeature) => (
        <div key={artistFeature.id} style={styles.listItem}>
          <p style={styles.tagText}>{artistFeature.tag}</p>
          <button onClick={() => removeArtistFeature(artistFeature.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryArtistFeatures}
          onChange={(e) => setSearchQueryArtistFeatures(e.target.value)}
          placeholder="Feature's Name or @username"
          
          maxLength={50}
        />
        <button onClick={addArtistFeature} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Producers</h2>
      {isSearchingProducers &&
        searchResultsProducers.map((user, index) => (
          <button key={index} onClick={() => selectProducer(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.producers.map((producer) => (
        <div key={producer.id} style={styles.listItem}>
          <p style={styles.tagText}>{producer.tag}</p>
          <button onClick={() => removeProducer(producer.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryProducers}
          onChange={(e) => setSearchQueryProducers(e.target.value)}
          placeholder="Producer's Name or @username"
          
          maxLength={50}
        />
        <button onClick={addProducer} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Songwriters</h2>
      {isSearchingSongwriters &&
        searchResultsSongwriters.map((user, index) => (
          <button key={index} onClick={() => selectSongwriter(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.songwriters.map((songwriter) => (
        <div key={songwriter.id} style={styles.listItem}>
          <p style={styles.tagText}>{songwriter.tag}</p>
          <button onClick={() => removeSongwriter(songwriter.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQuerySongwriters}
          onChange={(e) => setSearchQuerySongwriters(e.target.value)}
          placeholder="Songwriter's Name or @username"
          maxLength={50}
          
        />
        <button onClick={addSongwriter} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Record Labels</h2>
      {isSearchingRecordLabels &&
        searchResultsRecordLabels.map((user, index) => (
          <button key={index} onClick={() => selectRecordLabel(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.recordLabels.map((recordLabel) => (
        <div key={recordLabel.id} style={styles.listItem}>
          <p style={styles.tagText}>{recordLabel.tag}</p>
          <button onClick={() => removeRecordLabel(recordLabel.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryRecordLabels}
          onChange={(e) => setSearchQueryRecordLabels(e.target.value)}
          placeholder="Record Label's Name or @username"
          maxLength={50}
          
        />
        <button onClick={addRecordLabel} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      {albumMode && !editingAlbumDetails && (
        <div>
          {!addingSongs && (
            <button onClick={handleAlbumDetailsSubmit} style={{ color: '#62fbfa' }}>
              Start Adding Songs
            </button>
          )}
        </div>
      )}
    </div>
  );
};




const handleAddNewTrack = () => {
  const newTrack = {
    ...currentTrackDetails,
    artistName: albumDetails.artistName ? { ...albumDetails.artistName } : null,
    trackNumber: tracks.length + 1
  };

  setTracks([...tracks, newTrack]);

  setCurrentForm('Add Track');
};




const renderTrackFormContent = (editingTrack = {}) => {
  const isEditingExistingTrack = editingTrack && editingTrack.trackNumber;
  const trackDetails = isEditingExistingTrack ? editingTrack : currentTrackDetails;

  const handleGenreSelect = (event) => {
    setSelectedGenre(event.target.value);
  };


const addTrackGenre = () => {
  if (selectedGenre) {
    if (!currentTrackDetails.genres.includes(selectedGenre) && currentTrackDetails.genres.length < 1) {
      setCurrentTrackDetails(prevState => ({
        ...prevState,
        genres: [...prevState.genres, selectedGenre]
      }));
    }
    setSelectedGenre(null);
  }
};

const handleRemoveTrackGenre = (index) => {
  setCurrentTrackDetails(prevState => ({
    ...prevState,
    genres: prevState.genres.filter((_, i) => i !== index)
  }));
};






const selectTrackProducer = (user) => {
  if (currentTrackDetails.producers.length >= 5) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};


const handleAddProducer = () => {
  const input = searchQueryProducers.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.producers.some(p => p.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        producers: [...prevDetails.producers, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryProducers('');
};





const handleRemoveProducer = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    producers: prevDetails.producers.filter((_, i) => i !== index)
  }));
};


const selectTrackSongwriter = (user) => {
  if (currentTrackDetails.songwriters.length >= 5) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};
    
    
    const selectTrackArtistFeature = (user) => {
      if (currentTrackDetails.artistFeatures.length >= 5) {
        return;
      }

      const newArtistFeature = {
        tag: `@${user.username} - ${user.name}`,
        id: user.id
      };

      setCurrentTrackDetails(prevState => ({
        ...prevState,
        artistFeatures: [...prevState.artistFeatures, newArtistFeature]
      }));

      setSearchQueryArtistFeatures('');
      setIsSearchingArtistFeatures(false);
    };


const selectTrackRecordLabel = (user) => {
  if (currentTrackDetails.recordLabels.length >= 3) {
    return;
  }

  const newRecordLabel = {
        tag: `@${user.username} - ${user.name}`,
        id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};

const selectTrackArtistName = (user) => {
  if (currentTrackDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};




const handleAddSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.songwriters.some(s => s.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        songwriters: [...prevDetails.songwriters, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQuerySongwriters('');
};

const handleAddArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (input) {
    if (!currentTrackDetails.artistFeatures.some(a => a.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        artistFeatures: [...prevDetails.artistFeatures, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryArtistFeatures('');
};


const handleAddRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (input) {
    if (!currentTrackDetails.recordLabels.some(r => r.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        recordLabels: [...prevDetails.recordLabels, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryRecordLabels('');
};

const handleRemoveRecordLabel = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    recordLabels: prevDetails.recordLabels.filter((_, i) => i !== index)
  }));
};



const handleRemoveArtistName = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistName: null
  }));
};




const handleRemoveSongwriter = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    songwriters: prevDetails.songwriters.filter((_, i) => i !== index)
  }));
};

const handleRemoveArtistFeature = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistFeatures: prevDetails.artistFeatures.filter((_, i) => i !== index)
  }));
};



const handleAddTag = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: [...prevDetails.tags, selectedTag]
  }));
};



const handleRemoveTag = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: prevDetails.tags.filter((_, tagIndex) => tagIndex !== index)
  }));
};

const pickTrackArtwork = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const fileUrl = URL.createObjectURL(file);
    
    const img = new Image();
    img.src = fileUrl;
    
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const aspectRatio = width / height;
      
      // Check if the aspect ratio is close to 16:9 (1.777) or any other ratio you prefer
      if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
        setCurrentTrackDetails(prevDetails => ({
          ...prevDetails,
          artworkFile: file,
          artworkURL: fileUrl,
        }));
      } else {
        alert("Please select an image with a 16:9 ratio.");
      }
    };
  }
};









const handleReview = () => {
  

  // Add genres to the track
  addTrackGenre();
  handleAddProducer();
  handleAddSongwriter();
  handleAddRecordLabel();
  handleAddArtistFeature();

  // Log current track details after attempting to add genres
  

  // Add a delay
  setTimeout(() => {
    setCurrentTrackDetails(prevDetails => {
      

      const newTrack = {
        ...prevDetails,
        trackNumber: tracks.length + 1,
        artworkFile: prevDetails.artworkFile // Ensure artworkFile is preserved
      };

      

      const updatedTracks = [...tracks, newTrack];

      // Update the tracks state
      setTracks(updatedTracks);

      // Update the form state
      setCurrentForm(`Edit Track ${newTrack.trackNumber}`);
      setEditingAlbumDetails(false);
      setFormMode('edit');

      setIsReviewing(true);

      
      return newTrack; // Ensure to return the newTrack object
    });
  }, 100); // 100 milliseconds delay
};




return (
  
  <div key={`track-form-${currentTrackDetails.trackNumber}`} style={styles.scrollViewInnerContainer2}>
    
          <style>
        {`
          .input-placeholder::placeholder {
            color: black; /* Change this to your desired placeholder text color */
          }
        `}
      </style>

    <h3 style={styles.subtitle3}>SPNCaster</h3>
    {/* Display Selected Artist */}
    <div style={styles.list}>
      {currentTrackDetails.artistName && (
        <div key={currentTrackDetails.artistName.id} style={styles.listItem}>
          <span style={styles.tagText}>{currentTrackDetails.artistName.tag}</span>
        </div>
      )}
    </div>



{!albumMode && (
  <div style={{ marginTop: 20 }}>
  <button onClick={handleToggleCollaborators} style={styles.customButtonStyle2}>
    + Collaborators
  </button>
  {showCollaborators && (
    <div style={{ marginTop: 10 }}>
      {collaborators.map((collaborator, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <p style={{ color: 'white', textAlign: 'center' }}>Collaborator {index + 1}</p>
          
          {/* Search Input */}
          <input
            style={styles.inputWider}
            placeholder="Search by @username"
            value={collaborator.searchQuery}
            onChange={(e) => handleCollaboratorChange(index, 'searchQuery', e.target.value)}
          />
          
          {collaborator.searchResults && collaborator.searchResults.length > 0 && collaborator.searchQuery && 
            collaborator.searchResults.map((user, idx) => (
              <div key={idx} onClick={() => selectCollaborator(user, index)} style={{ cursor: 'pointer', marginBottom: 5 }}>
                <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
              </div>
            ))
          }

          {/* Display Selected Collaborator */}
          {collaborator.name && (
            <div style={styles.listItem}>
              <p style={styles.tagText}>{collaborator.name}</p>
              <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
                Remove Collaborator
              </button>
            </div>
          )}

          {/* Percentage Input */}
          <div style={styles.inputContainer2}>
            <div style={styles.inputWrapper}>
              <input
                style={styles.inputWider}
                placeholder="Percentage"
                value={collaborator.percentage}
                onChange={(e) => {
                  let formattedText = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  if (formattedText.startsWith('0')) {
                    formattedText = formattedText.slice(1); // Remove leading zero
                  }
                  formattedText = formattedText.slice(0, 2); // Limit to 2 digits
                  handleCollaboratorChange(index, 'percentage', formattedText);
                }}
                type="number"
                maxLength={2}
                disabled={!collaborator.name} // Disable if no collaborator is selected
              />
              <span style={styles.percentageSymbol}>%</span>
            </div>
          </div>

          <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
            Remove Collaborator
          </button>
        </div>
      ))}
      <button onClick={handleAddCollaborator} style={styles.customButtonStyle2}>
        {collaborators.length === 0 ? '+ Add Collaborator' : '+ Add Another Collaborator'}
      </button>
    </div>
  )}
</div>
)}

    <input
      type="file"
      accept="image/*"
      ref={inputFileRef}
      style={{ display: 'none' }}
      onChange={pickTrackArtwork}
    />
    <div style={styles.buttonContainer}>
      <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Choose Thumbnail</button>
    </div>
    <input type="file" accept="image/*" onChange={pickTrackArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
    {currentTrackDetails.artworkURL && (
      <img
        src={currentTrackDetails.artworkURL}
        style={styles.imagePreview}
        alt="Artwork Preview"
      />
    )}

<div style={styles.inputContainer3}>
  <div style={styles.pickerContainer}>
    <select
      onChange={(e) => setSelectedCategory(e.target.value)}
      style={styles.select}
      defaultValue=""
    >
      <option value="" disabled>Select Category...</option>
      {categoriesList.map((category) => (
        <option key={category.value} value={category.value}>
          {category.label}
        </option>
      ))}
    </select>
  </div>
</div>


{renderDynamicFields()}


    {/* Song File Button and Display */}
    <button onClick={pickVideo} style={styles.customButtonStyle2}>
      {trackDetails.songURL ? "Change Video File" : "Add Video File"}
    </button>

    {currentTrackDetails.songURL && (
      <span style={styles.selectedSong}>
        {currentTrackDetails.videoDisplayName || 'Song file selected'}
      </span>
    )} 

    {albumMode ? (
      <button onClick={formMode === 'edit' ? handleSaveTrackChanges : handleAddNewTrack} style={{ backgroundColor: 'transparent', color: "#62fbfa" }}>
        {formMode === 'edit' ? "Save Changes" : "Add Song"}
      </button>
    ) : (
      !isReviewing ? (
        <button onClick={handleReview} style={styles.reviewButton}>
          Review SPNCast
        </button>
      ) : (
        <button onClick={handleSaveSingleChanges} style={{ marginTop: '20px', backgroundColor: 'transparent', color: "#62fbfa", border: 'none', marginBottom: '-140px' }}>

        </button>
      )
    )}

  </div>
);

        };




        const [termsModalVisible, setTermsModalVisible] = useState(false);
        const [termsAccepted, setTermsAccepted] = useState(false);
        
        
        const DISPLAY_TEXT = `By selecting agree, you hereby acknowledge that you have full authorization to upload this content. You are also authorizing SPNDAT to distribute this content to all of it’s users throughout all of the app’s functions, and hold SPNDAT and any of their affiliated parties harmless to any copyright claims and infringements, as referenced in 3.3 of the Terms of Service - Music & Video Component.`;



        return (
          
          <div style={styles.fullScreen}>
            
                  <style>
        {`
          .input-placeholder::placeholder {
            color: white; /* Change this to your desired placeholder text color */
          }
        `}
      </style>
      {alertMessage && (
      <div style={styles.alertOverlay} onClick={() => setAlertMessage('')}>
        <div style={styles.alertBox}>
          <p style={styles.alertText}>{alertMessage}</p>
        </div>
      </div>
    )}
            <div
              style={{
                ...styles.scrollContainer,
                paddingTop: scrollAreaTopPadding,
                paddingBottom: height * 0.1,
                overflowY: 'scroll',
              }}
            >
              
              <div style={styles.scrollViewInnerContainer}>
                <h1 style={styles.title}>Release SPNCast</h1>

                
        

        
                {renderPickerModal()}
        
                {albumDetails.isLinked ? (
                  <>
                    {renderAlbumDetailsModuleLinked()}
                    {renderTrackModulesLinked()}
                  </>
                ) : (
                  <>
                    {albumMode && renderTrackForm()}
        
                    {albumMode &&
                      tracks.map((track, index) => (
                        <div key={index} style={styles.trackModule}>
                          <p style={{ ...styles.trackInfo, color: 'white' }}>
                            Track {track.trackNumber}: {track.title}
                          </p>
                          <button onClick={() => removeTrack(track.trackNumber)} style={{ color: "#f63bb0" }}>
                            Remove
                          </button>
                        </div>
                      ))}
        
                    {!albumMode && renderSingleSongForm()}
        
                    {!albumMode && isReviewing && renderTrackModules()}
        
                    {!albumDetails.isLinked && renderAlbumDetailsModule()}
        
                    {albumMode && addingSongs && renderTrackModules()}
        
                    {!albumMode && isReviewing && tracks.length > 0 && (
                      <button onClick={handleSubmit} style={styles.button}>
                        Release SPNCast
                      </button>
                    )}
        
                    {(tracks.length >= 5 || albumDetails.isLinked) && (
                      <button onClick={handleSubmit} style={{ color: "#62fbfa" }}>
                        Release SPNCast
                      </button>
                    )}
                  </>
                )}
        
                <div
                  style={{
                    display: termsModalVisible ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                  onClick={() => setTermsModalVisible(!termsModalVisible)}
                >
                  <div style={styles.centeredView} onClick={(e) => e.stopPropagation()}>
                    <div style={styles.modalView}>
                      <div style={{ marginBottom: 20, overflowY: 'scroll' }}>
                        <p style={styles.modalText}>{DISPLAY_TEXT}</p>
                      </div>
                      <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <button
                          style={styles.buttonAgree}
                          onClick={() => {
                            setTermsAccepted(true);
                            setTermsModalVisible(false);
                            handleSubmit();
                          }}
                        >
                          I Agree
                        </button>
                        <button
                          style={styles.buttonDisagree}
                          onClick={() => {
                            setTermsAccepted(false);
                            setTermsModalVisible(false);
                          }}
                        >
                          I Disagree
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div style={{ height: bottomAreaHeight, backgroundColor: 'transparent' }} />

            {editingLinkedTrack && (
              <>
                <img
                  src="./assets/DATBG.png"
                  style={styles.fullScreenImage2}
                  alt="DAT Background"
                />
    <input
      type="file"
      accept="video/*"
      ref={inputVideoRef}
      style={{ display: 'none' }}
      onChange={pickVideoLinked}
    />
    <button onClick={() => inputVideoRef.current.click()}>
      Upload Video File
    </button>
    <input
      type="file"
      accept="image/*"
      ref={inputFileRef}
      style={{ display: 'none' }}
      onChange={pickTrackThumbnail}
    />
    <button style={styles.artworkButton} onClick={() => inputFileRef.current.click()}>
      Choose Thumbnail
    </button>
              </>
            )}
                    <Modal
                    
  isOpen={currentlyReleasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 1000000,
    }
  }}
>
  <h2>Releasing SPNCast...</h2>
  <div style={{ width: '100%', height: '20px', backgroundColor: '#f3f3f3', borderRadius: '5px', marginTop: '20px' }}>
        <div
          style={{
            width: `${uploadProgress}%`,
            height: '100%',
            backgroundColor: uploadProgress === 100 ? 'green' : '#62fbfa',
            borderRadius: '5px',
            textAlign: 'center',
            color: 'black',
          }}
        >
          {uploadProgress.toFixed(2)}%
        </div>
        </div>
</Modal>
                          <div style={styles.extraSpace}></div>

          </div>
        );
        
};

const styles = {
  inputPlaceholder: {
    '::placeholder': {
      color: 'black', // Change this to your desired placeholder text color
    },
  },
  extraSpace: {
    height: '300px',
      },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    bottom: 130,
    position: 'relative',
    zIndex: 10,
  },
  removeButton: {
    backgroundColor: '#852061',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '10px',
    fontFamily: 'brhendrix',
  },
  reviewButton: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    padding: 10,
    fontSize: '16px',
    borderRadius: 5,
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    position: 'relative',
    zIndex: 10,
  },
  fullScreenImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  fullScreenImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  fullScreenImage2: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 100,
  },

  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',

    marginTop: '10px',
    zIndex: 3,
    color: 'white',
    marginBottom: 20,
  },
  genresTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
    marginTop: '5px',
    marginBottom: '-10px',
    zIndex: 3,
    color: 'white',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: 20,
    zIndex: 3,
    fontFamily: 'brhendrix',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginBottom: 8,
    marginTop: 15,
    zIndex: 3,
    color: 'white',
  },
  alertOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black background with 0.7 opacity
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000, // Ensure it is on top of other content
  },
  alertBox: {
    backgroundColor: 'black', // Black background
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  alertText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  subtitle3: {
    fontSize: '20px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
    alignSelf: 'center',
    zIndex: 3,
    color: 'white',
  },
  subtitle4: {
    fontSize: '16px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginBottom: 8,
    marginTop: 8,
    zIndex: 3,
    color: 'white',
  },
  artworkButton: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '15px',
    display: 'flex',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
    borderWidth: '1px',
    borderColor: 'gray',
    borderStyle: 'solid',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  inputWider: {
    width: '87%',
    fontFamily: 'brhendrix',
    fontSize: '16px',
    textAlign: 'left',
    padding: '8px',
    backgroundColor: 'transparent',
    marginBottom: '4px',
    marginTop: '20px',
    borderWidth: '1px',
    borderColor: 'gray',
    color: 'white',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    zIndex: 3,
    boxSizing: 'border-box',
    borderStyle: 'solid', // Ensure a consistent border style
  },
  
  inputWider2: {
    width: '87%',
    alignSelf: 'center',
    display: 'flex',
    textAlign: 'left',
    color: 'white',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'brhendrix',
    padding: 8,
    marginBottom: 4,
    marginTop: 4,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 8,
    zIndex: 3,
  },
  input: {
    width: '87%',
    color: 'white',
    padding: '5px', // Increase padding for a larger input area
    fontSize: '16px', // Increase font size for better readability
    textAlign: 'left', // Centered text
    marginBottom: 20, // Increase margin bottom for spacing
    borderRadius: 5, // Rounded corners
    border: '1px solid #ccc', // Border styling
    backgroundColor: 'transparent', // Transparent background
  },
  inputContainer: {
    width: '87%',
    display: 'flex',
    flexDirection: 'column', // Change to column to align vertically
    alignSelf: 'center',
    justifyContent: 'flex-start', // Align content to the top
    alignItems: 'flex-start', // Align items to the left
    backgroundColor: 'transparent',
    marginBottom: '20px', // Add some margin for spacing between containers
  },
  inputContainer3: {
    width: '87%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    backgroundColor: 'transparent',
  },
  imagePreview: {
    width: '60%',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',

    height: 'auto',
    aspectRatio: '16 / 9',
    borderRadius: 8,
    marginBottom: 10,
    zIndex: 3,
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    zIndex: 3,
    fontFamily: 'brhendrix',
  },
  tagText: {
    zIndex: 3,
    fontFamily: 'brhendrix',
    color: 'white',
    fontSize: '16px',
  },
  tagItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#240f48',
    padding: '5px 10px',
    borderRadius: 20,
    marginVertical: 5,
    marginRight: 5,
    fontFamily: 'brhendrix',
    zIndex: 3,
  },
  removeButton: {
    marginLeft: '2%',
    color: '#f63bb0',
    zIndex: 3,
    fontFamily: 'brhendrix',
    fontSize: '16px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#240f48',
    padding: '5px 10px',
    borderRadius: 5,
    marginVertical: 5,
    zIndex: 3,
  },
  customButtonStyle: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: '10px',
    border: 'none',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  customButtonStyle2: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: '10px',
    border: 'none',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  customButtonText: {
    color: "#62fbfa",
    fontSize: '16px',
  },
  customButtonText2: {
    color: "#62fbfa",
    fontSize: '16px',
  },
  selectedSong: {
    marginTop: 10,
    fontSize: '2.4vw',
    zIndex: 3,
    fontFamily: 'brhendrix',
    color: 'white',
  },
  songItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 5,
    zIndex: 3,
  },
  songTitle: {
    fontSize: '2.4vw',
    fontFamily: 'brhendrix',
  },
  scrollViewContainer: {
    padding: 20,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 20,
    overflowY: 'auto', // Ensure vertical scrolling for the entire container
  },
  scrollViewInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '23px',
    justifyContent: 'flex-start',
    minHeight: '100%',
    width: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  scrollViewInnerContainer2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  scrollViewInnerContainerCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  modeToggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  activeModeText: {
    color: '#62fbfa',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
  },
  inactiveModeText: {
    color: '#b3b3b3',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
  },
  wheelSelector: {
    width: 150,
    height: 150,
  },
  albumInfoContainer: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  albumInfoText: {
    color: 'white',
    textAlign: 'center',
    fontSize: '18px',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
    padding: 3,
  },
  trackInfo: {
    color: 'white',
    textAlign: 'center',
    fontSize: '2.4vw',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
    padding: 3,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionButton: {
    backgroundColor: '#ffffff',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    zIndex: 101,
  },
  popupImage: {
    position: 'absolute',
    resizeMode: 'cover',
  },
  button: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    color: '#62fbfa',
    marginBottom: '40px',
    zIndex: 1000,
  },
  buttonText: {
    color: '#62fbfa',
    fontSize: 16,
  },
  centeredView: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  buttonAgree: {
    backgroundColor: "#2196F3",
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    marginVertical: 10,
    marginLeft: '26%',
  },
  modalView: {
    marginTop: '29%',
    marginBottom: '42%',
    backgroundColor: "black",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  modalText: {
    color: "white",
    marginBottom: '2vh',
    fontSize: '3.5vw',
    lineHeight: 30,
    marginTop: '2vh',
    fontFamily: 'brhendrix',
    textAlign: "center",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonDisagree: {
    backgroundColor: "#f44336",
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    marginVertical: 10,
  },
};


    export default ReleaseSPNCast;


