import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { collection, query, getDocs, startAfter, orderBy, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import SongItemSeeAll from './SongItemSeeAll';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProfileComponentProducerSmall } from './ProfileComponentProducerSmall';
import { AppContext } from './AppContext';

const genresList = [
  { label: "Country", value: "country" },
  { label: "Rock", value: "rock" },
  { label: "Rap", value: "hip_hop_rap" },
  { label: "Pop", value: "pop" },
  { label: "Alternative", value: "Alternative" },
  { label: "Blues", value: "blues" },
  { label: "R&B/Soul", value: "rnb_soul" },
  { label: "Metal", value: "metal" },
  { label: "Indie", value: "indie" },
  { label: "Folk", value: "folk" },
  { label: "Classical", value: "classical" },
  { label: "Electronic/Dance", value: "electronic_dance" },
  { label: "Jazz", value: "jazz" },
  { label: "Latin", value: "latin" },
  { label: "Reggae", value: "reggae" },
  { label: "World/International", value: "world_international" }
];

const MusicSeeAll = ({ genre }) => {
  const dataRef = useRef([]); // Use useRef for data
  const lastDocRef = useRef(null); // Use useRef for lastDoc
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { category } = location.state;

  const { followers, setCurrentlyLoadingProfile, currentUser, setActivePopupMedia, activePopupMedia, userId2 } = useContext(AppContext);

  const scrollContainerRef = useRef(null);
  const sentinelRef = useRef(null);

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  const fetchCategoryData = async () => {
    if (isFetching) {
      console.log('Already fetching data');
      return;
    }
    setIsFetching(true);

    setLoading(true);
    let genreValue = genresList.find(g => g.label === category)?.value;

    try {
        if (genreValue) {
            
            const fetchSize = 16;

            const singlesQuery = query(
                collection(firestore, `MusicGenres/${genreValue}/Singles`),
                orderBy('releaseTimestamp', 'desc'),
                limit(fetchSize / 2)
            );

            const albumsQuery = query(
                collection(firestore, `MusicGenres/${genreValue}/Albums`),
                orderBy('releaseTimestamp', 'desc'),
                limit(fetchSize / 2)
            );

            const [singlesSnapshot, albumsSnapshot] = await Promise.all([
                getDocs(singlesQuery),
                getDocs(albumsQuery)
            ]);

            const singlesData = singlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const albumsData = albumsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const fetchedData = [...singlesData, ...albumsData];
            fetchedData.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

            dataRef.current = fetchedData; // Update the ref with fetched data

            // Store lastDoc in the ref
            lastDocRef.current = {
                singles: singlesSnapshot.docs[singlesSnapshot.docs.length - 1],
                albums: albumsSnapshot.docs[albumsSnapshot.docs.length - 1]
            };

        } else {
            console.log('Fetching from general category collection');

            let collectionPath;
            if (category === 'FEATURED MUSIC') {
                collectionPath = 'FeaturedMusic';
            } else if (category === 'NEW ALBUMS') {
                collectionPath = 'Albums';
            } else if (category === 'Featured Artists') {
                collectionPath = 'Featured Artists';
            } else if (category === 'Producers') {
                collectionPath = 'ProducersWithBeats';
            } else {
                collectionPath = 'Songs';
            }
            

            const collectionRef = collection(firestore, collectionPath);


            const orderField = (category === 'Producers' || category === 'Featured Artists') ? 'name' : 'releaseTimestamp';
            const orderDirection = (category === 'Producers' || category === 'Featured Artists') ? 'asc' : 'desc';

            const initialQuery = query(collectionRef, orderBy(orderField, orderDirection), limit(16));
            const snapshot = await getDocs(initialQuery);

            const fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            dataRef.current = fetchedData; // Update the ref with fetched data

            // Store lastDoc in the ref
            lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];

        }
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      setIsFetching(false);
        setLoading(false);
        console.log('Fetch category data completed');
    }
};

const fetchMoreData = async () => {
  console.log('Fetching more data...');
  if (isFetchingMore || !dataRef.current.length) {

    return;
  }



  let theLastDoc = lastDocRef.current; // Access the ref value



  setIsFetchingMore(true);
  let genreValue = genresList.find(g => g.label === category)?.value;


  try {
    let fetchedData = [];
    const fetchSize = 16;

    if (genreValue) {


      const singlesQuery = query(
        collection(firestore, `MusicGenres/${genreValue}/Singles`),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(theLastDoc.singles),
        limit(fetchSize / 2)
      );

      const albumsQuery = query(
        collection(firestore, `MusicGenres/${genreValue}/Albums`),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(theLastDoc.albums),
        limit(fetchSize / 2)
      );

      const [singlesSnapshot, albumsSnapshot] = await Promise.all([
        theLastDoc.singles ? getDocs(singlesQuery) : Promise.resolve({ docs: [] }),
        theLastDoc.albums ? getDocs(albumsQuery) : Promise.resolve({ docs: [] }),
      ]);

      const singlesData = singlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const albumsData = albumsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      fetchedData = [...singlesData, ...albumsData];
      fetchedData.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

      dataRef.current = [...dataRef.current, ...fetchedData]; // Append new data to ref

      // Update lastDocRef with the new last documents
      lastDocRef.current = {
        singles: singlesSnapshot.docs.length > 0 ? singlesSnapshot.docs[singlesSnapshot.docs.length - 1] : lastDocRef.current.singles,
        albums: albumsSnapshot.docs.length > 0 ? albumsSnapshot.docs[albumsSnapshot.docs.length - 1] : lastDocRef.current.albums,
      };

    } else {
      console.log('Fetching more from general category collection');

      let collectionPath;
      if (category === 'FEATURED MUSIC') {
          collectionPath = 'FeaturedMusic';
      } else if (category === 'NEW ALBUMS') {
          collectionPath = 'Albums';
      } else if (category === 'Featured Artists') {
          collectionPath = 'Featured Artists';
      } else if (category === 'Producers') {
          collectionPath = 'ProducersWithBeats';
      } else {
          collectionPath = 'Songs';
      }
      

      const collectionRef = collection(firestore, collectionPath);
      const orderField = (category === 'Producers' || category === 'Featured Artists') ? 'name' : 'releaseTimestamp';
      const orderDirection = (category === 'Producers' || category === 'Featured Artists') ? 'asc' : 'desc';

      const nextQuery = query(
        collectionRef,
        orderBy(orderField, orderDirection),
        startAfter(theLastDoc),
        limit(fetchSize)
      );
      const snapshot = await getDocs(nextQuery);

      fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      dataRef.current = [...dataRef.current, ...fetchedData]; // Append new data to ref

      if (snapshot.docs.length > 0) {
        lastDocRef.current = snapshot.docs[snapshot.docs.length - 1]; // Update the last document
      }
    }


  } catch (error) {
    console.error('Error fetching more data:', error);
  } finally {
    setIsFetchingMore(false);
    console.log('Fetch more data completed');
  }
};

useEffect(() => {
  fetchCategoryData();

  const observer = new IntersectionObserver(handleIntersection, {
    root: scrollContainerRef.current,
    threshold: 0.75,
  });

  if (sentinelRef.current) {
    observer.observe(sentinelRef.current);
  }

  return () => {
    if (sentinelRef.current) {
      observer.unobserve(sentinelRef.current);
    }
  };
}, []);

const handleIntersection = (entries) => {
  const entry = entries[0];

  if (entry.isIntersecting && !isFetchingMore && !isFetching) {
    fetchMoreData();
  }
};

const handlePressBegins = async (song) => {
  navigate('/view-song', { state: { song } });
};

const renderItem = useCallback((item) => {
  
  if (category === 'Featured Artists' || category === 'Producers') {
    return (
      <ProfileComponentProducerSmall 
        key={item.id} 
        item={item} 
        onClick={() => handlePressProfile(item)} 
      />
    );
  } else {
    return (
      <SongItemSeeAll 
        key={item.id}  
        item={item} 
        onPress={() => handlePressBegins(item)} 
      />
    );
  }
}, [category, handlePressProfile, handlePressBegins]);



return (
  <div style={styles.fullScreen}>
    <div style={styles.outerContainer} ref={scrollContainerRef}>
      <div style={styles.container}>
        <h2 style={styles.title}>{category}</h2>
        {loading ? (
          <div className="loading-spinner" style={styles.spinner}></div>
        ) : (
          <div style={styles.gridContainer}>
            {dataRef.current.map(renderItem)} {/* Render using dataRef */}
          </div>
        )}
        <div ref={sentinelRef} style={{ height: '20px', backgroundColor: 'transparent' }}></div>
      </div>
    </div>
  </div>
);
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  outerContainer: {
    position: 'relative',
    height: 'calc(100vh - 19vh)',
    zIndex: 10,
    overflowY: 'scroll',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '0 4.2%',
    overflow: 'visible',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '20px',
    textAlign: 'center',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    paddingBottom: '19vh',
  },
  spinner: {
    display: 'block',
    margin: 'auto',
    borderColor: '#62fbfa',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderRadius: '50%',
    borderTopColor: 'transparent',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
};

export default MusicSeeAll;
