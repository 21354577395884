import React, { useState, useContext } from 'react';
import { ref, get, set, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { database } from './firebase'; // Ensure your Firebase configuration is imported
import { AppContext } from './AppContext';

const ChatInput = ({ callId, isKeyboardVisible }) => {
  const [message, setMessage] = useState('');
  const auth = getAuth();
  const { currentUser } = useContext(AppContext);

  const sendMessage = async () => {
    if (message.trim()) {
      const user = auth.currentUser;
      const messageData = {
        userId: user.uid,
        username: currentUser.username,
        profileImageUrl: currentUser.profileImageUrl,
        accountType: currentUser.accountType,
        message,
        timestamp: Date.now(),
      };

      console.log(messageData);

      const livestreamDocRef = ref(database, `livestreams/${callId}`);
      const livestreamDoc = await get(livestreamDocRef);

      if (livestreamDoc.exists()) {
        let { currentMessageDocId, messageDocs } = livestreamDoc.val();
        const currentMessageDocRef = ref(database, `livestreams/${callId}/messages/${currentMessageDocId}`);

        try {
          const currentMessagesSnapshot = await get(currentMessageDocRef);
          const currentMessages = currentMessagesSnapshot.exists() ? currentMessagesSnapshot.val().messages : [];

          currentMessages.push(messageData);

          await update(currentMessageDocRef, {
            messages: currentMessages,
          });
        } catch (error) {
          if (error.message.includes('exceeded')) {
            let newMessageDocId;
            if (currentMessageDocId.includes('-')) {
              const parts = currentMessageDocId.split('-');
              newMessageDocId = `${parts[0]}-${parseInt(parts[1], 10) + 1}`;
            } else {
              newMessageDocId = `${currentMessageDocId}-2`;
            }

            const newMessageDocRef = ref(database, `livestreams/${callId}/messages/${newMessageDocId}`);

            await set(newMessageDocRef, {
              messages: [messageData],
            });

            messageDocs.push(newMessageDocId);

            await update(livestreamDocRef, {
              currentMessageDocId: newMessageDocId,
              messageDocs,
            });
          } else {
            console.error('Error sending message:', error);
          }
        }
      } else {
        console.error('Livestream document not found');
      }

      setMessage('');
    }
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    position: 'absolute',
    bottom: isKeyboardVisible ? '40%' : '10%',
    width: '100%',
    zIndex: 1000,
  };

  return (
    <div style={containerStyle}>
      <input
        type="text"
        style={styles.input}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message"
        maxLength={200} // Character limit
      />
      <button style={styles.sendButton} onClick={sendMessage}>
        <span style={styles.sendButtonText}>Send</span>
      </button>
    </div>
  );
};

const styles = {
  input: {
    flex: 1,
    height: '40px',
    backgroundColor: 'rgba(81, 33, 131, 0.7)',
    borderRadius: '20px',
    padding: '0 15px',
    fontSize: '16px',
    marginRight: '10px',
    color: 'white',
    border: 'none',
    outline: 'none',
  },
  sendButton: {
    backgroundColor: 'rgba(81, 33, 131, 0.7)',
    borderRadius: '20px',
    padding: '8px 16px',
    border: 'none',
    cursor: 'pointer',
  },
  sendButtonText: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export default ChatInput;
