import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from './AppContext';
import ProfileBGLive from './assets/ProfilePicBGlive.png';
import DefaultProfilePic from './assets/defaultProfilePicProfile.png';
import ProfileBG from './assets/ProfilePicBG.png';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };

const MYDATProfilePicSelectorUser = ({ isLive, openDialog, profile }) => {
  const { viewingProfile, startViewingLivestream, currentUser, profileImageUrl } = useContext(AppContext);
  const fileInputRef = useRef(null);

  
  useEffect(() => {
    console.log('viewingProfile check', viewingProfile)
  }, [isLive]);

  const profileImageSource = profile?.profileImageUrl || DefaultProfilePic;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
    }
  };

  

  const handleClick = () => {
      openDialog();
  };

  return (
    <div style={{ ...styles.touchableOpacity }} onClick={handleClick} >
      <div
        style={{
          ...styles.imageBackground,
          ...styles.touchableOpacity,
        }}
      >
        <div style={styles.imageContainer}>

        {isLive && (
  <img src={ProfileBGLive} alt="Profile Live" style={styles.image2} />
)}

          <img src={profileImageSource} alt="Profile" style={styles.image} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  touchableOpacity: {
    width: '160px',
    height: '160px',
    zIndex: 200,
    cursor: 'pointer',
    display: 'flex', // Ensure the div uses flexbox
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${ProfileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageContainer: {
    position: 'relative', // Enable stacking of child elements
    display: 'flex', // Ensure both images are centered within the container
    justifyContent: 'center',
    alignItems: 'center',
    width: '87%',
    height: '87%', // Increase container height to accommodate the border effect
    marginRight: 0.5,
    marginBottom: 2,
    borderRadius: '50%',
    overflow: 'visible', // Ensure the background image is visible beyond the container
  },
  image: {
    position: 'absolute', // Profile image on top
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the image
    width: '90%', // Slightly smaller than the live background
    height: '90%',
    objectFit: 'cover',
    zIndex: 2, // Higher z-index to appear above
    borderRadius: '50%',
  },
  image2: {
    position: 'absolute', // Live background is behind
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the image
    width: '115%', // Slightly larger than the profile image
    height: '115%',
    objectFit: 'cover',
    zIndex: 1, // Lower z-index to be in the background
    borderRadius: '50%', // Ensure it appears as a circle
  },
};

export default MYDATProfilePicSelectorUser;
