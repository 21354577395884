import React, { useState, useEffect, useContext } from 'react';
import { StreamVideo, StreamCall } from '@stream-io/video-react-sdk';
import LivestreamView from './LivestreamView';
import ChatDisplay from './ChatDisplay';
import ChatInput from './ChatInput';
import { database } from './firebase'; // Ensure you have your Firebase configuration
import { AppContext } from './AppContext';

const LivestreamPage = ({  }) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const { 
    callId,
    client,
    clientView,
    call,
    setParticipants,
    isChatHidden,
    setIsChatHidden,
    hideViewers,
    setHideViewers,
    isLivestreamEnded,
    setIsLivestreamEnded,
    isViewingLivestream,
    setIsViewingLivestream,
    participants,
    setCallIdView,
    setClientView,
    startLivestream,
    stopLivestream, handleHideViewers, handleHideChat
  } = useContext(AppContext);



  return (
    <div style={{ position: 'relative', height: '100vh', backgroundColor: 'black' }}>
      {callId && client && (
        <>
          <StreamVideo client={client}>
            <StreamCall call={call}>
              <div style={{ height: '100%', backgroundColor: 'black', zIndex: 10 }}>
                <LivestreamView
                  participants={participants}
                  isChatHidden={isChatHidden}
                  handleHideChat={handleHideChat}
                  hideViewers={hideViewers}
                  handleHideViewers={handleHideViewers}
                  call={call}
                  onStopLive={stopLivestream}
                />
              </div>
            </StreamCall>
          </StreamVideo>

          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '10px',
              backgroundColor: 'rgba(81, 33, 131, 0.7)',
              color: 'white',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              zIndex: 20,
            }}
            onClick={handleHideChat}
          >
            {isChatHidden ? 'Show Chat' : 'Hide Chat'}
          </button>

          {!isChatHidden && (
            <>
              {/* ChatDisplay */}
              <div
                style={{
                  position: 'absolute',
                  bottom: isKeyboardVisible ? '44%' : '14%',
                  width: '100%',
                  height: '30%',
                  zIndex: 200,
                }}
              >
                <ChatDisplay
                />
              </div>

              {/* ChatInput */}
              <div
                style={{
                  position: 'absolute',
                  bottom: '10%',
                  width: '100%',
                  zIndex: 20,
                }}
              >
                <ChatInput
                  isKeyboardVisible={isKeyboardVisible}
                  callId={callId}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LivestreamPage;
