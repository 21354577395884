import React, { useEffect, useContext } from 'react';
import { useCallStateHooks, Video } from '@stream-io/video-react-sdk';
import { AppContext } from './AppContext';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import DefaultsProfilePic from './assets/defaultProfilePic.png';

const LivestreamView = ({ handleHideChat, handleHideViewers, onStopLive }) => {
    const { useParticipantCount, useLocalParticipant, useIsCallLive } = useCallStateHooks();
    const totalParticipants = useParticipantCount();
    const localParticipant = useLocalParticipant();
    const isCallLive = useIsCallLive();

    const { 
      callId,
      client,
      clientView,
      call,
      setParticipants,
      isChatHidden,
      setIsChatHidden,
      hideViewers,
      setHideViewers,
      isLivestreamEnded,
      setIsLivestreamEnded,
      isViewingLivestream,
      setIsViewingLivestream,
      participants,
      setCallIdView,
      setClientView,
      startLivestream,
      stopLivestream,
      livestreamingVisible, setLiveStreamingVisible
    } = useContext(AppContext);

    const artistUsernames = ['@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Cole', 'Sonny', 'Anuva', 'CharlieP'];

    useEffect(() => {
        console.log('isCallLive:', isCallLive);
    }, [isCallLive]);

    const handleStopLive = async () => {
        try {
            console.log('Stopping live stream...');
            await call.endCall();
            onStopLive();
            console.log('Live stream stopped');
        } catch (error) {
            console.error('Error stopping live stream:', error);
        }
    };

    const renderIcon = (username, accountType) => {
        const isArtistIcon2Visible = artistUsernames.includes(`@${username}`) || artistUsernames.includes(username);
        if (accountType !== 'Fan') {
            return (
                <img
                    src={isArtistIcon2Visible ? ArtistIcon2 : ArtistIcon1}
                    alt="icon"
                    style={styles.iconStyle}
                />
            );
        }
        return null;
    };

    return (
        <div style={styles.container}>
            <div style={styles.liveContainer}>
                <span style={{ color: 'white' }}>👁</span>
                <span style={styles.text}>{participants?.length}</span>
            </div>
            <div style={styles.flexed}>
                {localParticipant && (
                    <Video participant={localParticipant} trackType="videoTrack" style={styles.videoRenderer} />
                )}
            </div>
            {!hideViewers && !isChatHidden && (
                <div style={styles.participantsList}>
                    {participants.map((participant, index) => (
                        <div key={index} style={styles.participantItem}>
                            <img
                                src={participant?.profileImageUrl ? participant?.profileImageUrl : DefaultsProfilePic}
                                alt="profile"
                                style={styles.profileImage}
                            />
                            <span style={styles.participantText}>
                                @{participant.username}{renderIcon(participant.username, participant.accountType)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            {!isChatHidden && (
                <button style={styles.viewersButton} onClick={handleHideViewers}>
                    {hideViewers ? 'Show Viewers' : 'Hide Viewers'}
                </button>
            )}
            <div style={styles.bottomBar}>
                {isCallLive && (
                    <button style={styles.leaveButton} onClick={handleStopLive}>
                        End Stream
                    </button>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: 'relative',
        backgroundColor: '#000',
        height: '100vh',
    },
    viewersButton: {
        position: 'absolute',
        top: '10%',
        right: '10px',
        backgroundColor: 'black',
        opacity: 0.7,
        padding: '6px',
        borderRadius: '5px',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    participantsList: {
        position: 'absolute',
        top: '20%',
        right: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        width: '30%',
        height: '30%',
        borderRadius: '10px',
        padding: '10px',
        overflowY: 'scroll',
    },
    participantItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    iconStyle: {
        width: '20px',
        height: '20px',
    },
    profileImage: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        marginRight: '10px',
    },
    participantText: {
        color: 'white',
        fontSize: '14px',
    },
    liveContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
        margin: '10px 0',
    },
    text: {
        marginLeft: '5px',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    hideChatButton: {
        position: 'absolute',
        top: '5%',
        right: '10px',
        backgroundColor: 'black',
        opacity: 0.7,
        padding: '6px',
        borderRadius: '5px',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    leaveButton: {
        backgroundColor: '#512183',
        opacity: 0.7,
        padding: '10px',
        borderRadius: '5px',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    flexed: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    videoRenderer: {
        width: '100%',
        maxHeight: '400px',
        border: '1px solid white',
    },
    bottomBar: {
        textAlign: 'center',
        margin: '10px 0',
    },
};

export default LivestreamView;
