import React, { useContext, useEffect, useState } from 'react';
import {
  StreamCall,
  useCallStateHooks,
  useStreamVideoClient,
  Video,
  Call,
} from "@stream-io/video-react-sdk";
import { AppContext } from './AppContext';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import DefaultsProfilePic from './assets/defaultProfilePic.png';

const CustomLivestreamPlayer = ({
  callType,
  onLeave,
  handleHideViewers,
  handleHideChat,
}) => {


  const { 
    callId,
    client,
    clientView,
    callIdView,
    call,
    setCall,
    setParticipants,
    isChatHidden,
    setIsChatHidden,
    hideViewers,
    setHideViewers,
    isLivestreamEnded,
    setIsLivestreamEnded,
    isViewingLivestream,
    setIsViewingLivestream,
    participants,
    setCallIdView,
    setClientView,
    startLivestream,
    stopLivestream,
    livestreamingVisible, setLiveStreamingVisible
  } = useContext(AppContext);


  const artistUsernames = ['@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Cole', 'Sonny', 'Anuva', 'CharlieP'];

  useEffect(() => {
    if (!clientView) return;
    const myCall = clientView.call('livestream', callIdView);
    setCall(myCall);
    myCall.join().catch((e) => {
      console.error('Failed to join call', e);
    });
    return () => {
      if (myCall.state.callingState !== 'left') {
        myCall.leave().catch((e) => {
          console.error('Failed to leave call', e);
        });
      }
      setCall(undefined);
    };
  }, [callIdView, clientView]);

  if (!call) {
    return null;
  }

  const handleLeave = async () => {
    try {
      await call.leave();
      setIsViewingLivestream(false);
      setCallIdView(null);
      setClientView(null);
    } catch (error) {
      console.error('Error leaving call:', error);
    }
  };

  const renderIcon = (username, accountType) => {
    const isArtistIcon2Visible = artistUsernames.includes(`@${username}`) || artistUsernames.includes(username);
    if (accountType !== 'Fan') {
      return (
        <img
          src={isArtistIcon2Visible ? ArtistIcon2 : ArtistIcon1}
          alt="icon"
          style={styles.iconStyle}
        />
      );
    }
    return null;
  };

  return (
<StreamCall call={call}>
    <div style={styles.container}>
        <div style={styles.flexed}>
            <CustomLivestreamLayout
                isChatHidden={isChatHidden}
                handleHideChat={handleHideChat}
                participants={participants}
                handleLeave={handleLeave}
            />
        </div>
        {!hideViewers && !isChatHidden && (
            <div style={styles.participantsList}>
                {participants.map((participant, index) => (
                    <div key={index} style={styles.participantItem}>
                        <img
                            src={participant?.profileImageUrl ? participant?.profileImageUrl : DefaultsProfilePic}
                            alt="profile"
                            style={styles.profileImage}
                        />
                        <span style={styles.participantText}>
                            @{participant.username}{renderIcon(participant.username, participant.accountType)}
                        </span>
                    </div>
                ))}
            </div>
        )}
        {!isChatHidden && (
            <button style={styles.viewersButton} onClick={handleHideViewers}>
                {hideViewers ? 'Show Viewers' : 'Hide Viewers'}
            </button>
        )}
    </div>
</StreamCall>

  );
};

const CustomLivestreamLayout = ({ handleLeave, participants, handleHideChat, isChatHidden }) => {
  const { useParticipants, useParticipantCount } = useCallStateHooks();
  const participantCount = useParticipantCount();
  const streamParticipants = useParticipants();



  // Handle the first participant
  const [firstParticipant] = streamParticipants || [];

  useEffect(() => {
    if (!firstParticipant) {
      console.warn('No first participant available.');
    }
  }, [firstParticipant]);

  return (
    <div style={styles.container}>
      <div style={styles.liveContainer}>
        <span style={{ color: 'white' }}>👁</span>
        <span style={styles.text}>{participants?.length || 0}</span>
      </div>
      <div style={styles.flexed}>
        {firstParticipant ? (
          <Video participant={firstParticipant} trackType="videoTrack" style={styles.videoRenderer} />
        ) : (
          <span style={styles.text}>The host hasn't joined yet</span>
        )}
                <div style={styles.bottomBar}>
            <button style={styles.leaveButton} onClick={handleLeave}>
                Leave Stream
            </button>
        </div>
      </div>
    </div>
  );
};


const styles = {
  flexed: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    height: '100vh',
    position: 'relative',
  },
  bottomBar: {
    textAlign: 'center',
    margin: '10px 0',
    height: '7%',
    width: '100%',

    backgroundColor: 'transparent',
    zIndex: 9000,
},

  videoRenderer: {
    width: '100%',
    maxHeight: '400px',
    border: '1px solid white',
},
participantsList: {
  position: 'absolute',
  top: '20%',
  right: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  width: '30%',
  height: '30%',
  borderRadius: '10px',
  padding: '10px',
  overflowY: 'scroll',
},
  hideChatButton: {
    position: 'absolute',
    top: '5%',
    right: '10px',
    backgroundColor: 'black',
    opacity: 0.7,
    padding: '6px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  viewersButton: {
    position: 'absolute',
    top: '10%',
    right: '10px',
    backgroundColor: 'black',
    opacity: 0.7,
    padding: '6px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  participantItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  iconStyle: {
    width: '20px',
    height: '20px',
  },
  profileImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  participantText: {
    color: 'white',
    fontSize: '14px',
  },
  container: {
    position: 'relative',
    backgroundColor: '#000',
    height: '100vh',
},
liveContainer: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px',
  margin: '10px 0',
},
  text: {
    marginLeft: '5px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  leaveButton: {
    backgroundColor: '#512183',
    opacity: 0.9,
    padding: '10px 20px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px auto',
    display: 'inline-block',
    cursor: 'pointer',
    zIndex: 1001,
    border: 'none', // Debug border
},
};

export default CustomLivestreamPlayer;
