import React, { useEffect, useState, useRef, useContext } from 'react';
import { ref, get, set, remove, onValue, onDisconnect } from 'firebase/database';
import { database } from './firebase';
import { AppContext } from './AppContext';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import DefaultsProfilePic from './assets/defaultProfilePic.png';

const ChatDisplay = ({ callId
}) => {
  const [messages, setMessages] = useState([]);
  const [scrollOffset, setScrollOffset] = useState(0);
  const scrollViewRef = useRef(null);

  const { 
    client,
    clientView,
    call,
    setParticipants,
    isChatHidden,
    setIsChatHidden,
    hideViewers,
    setHideViewers,
    isLivestreamEnded,
    setIsLivestreamEnded,
    isViewingLivestream,
    setIsViewingLivestream,
    participants,
    setCallIdView,
    setClientView,
    startLivestream,
    stopLivestream, handleHideViewers, handleHideChat
  } = useContext(AppContext);

  const artistUsernames = ['@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Cole', 'Sonny', 'Anuva', 'CharlieP'];
  const { currentUser, setStreamerRequests, isStreamer, setIsStreamer } = useContext(AppContext);

  useEffect(() => {
    if (!callId) return;

    const livestreamRef = ref(database, `livestreams/${callId}`);
    const participantKey = `${currentUser.username}`;
    const participantRef = ref(database, `livestreams/${callId}/participants/${participantKey}`);

    const handleUpdates = (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setMessages(data.messages ? Object.values(data.messages).reverse() : []);
        setParticipants(data.participants ? Object.values(data.participants) : []);
        console.log('participants check', data.participants);
      } else {
        setMessages([]);
        setParticipants([]);
      }
    };

    get(livestreamRef).then((snapshot) => {
      if (snapshot.exists()) {
        const { host } = snapshot.val();
        if (host !== currentUser.userId) {
          set(participantRef, {
            username: currentUser.username,
            name: currentUser.name,
            accountType: currentUser.accountType,
            profileImageUrl: currentUser.profileImageUrl,
          });
          onDisconnect(participantRef).remove();
        }
      }
    });

    onValue(livestreamRef, handleUpdates);

    return () => {
      remove(participantRef);
      setMessages([]);
      setParticipants([]);
    };
  }, [callId]);

  useEffect(() => {
    console.log('Messages:', messages);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [messages]);

  const calculateOpacity = (index) => {
    const fadeStart = 0.3; // Percent from top
    const fadeEnd = 1.0; // Full opacity at 100%
    const messageCount = messages.length;

    if (messageCount <= 10) {
      return 1; // All messages visible for small number
    }

    const fadeIndex = Math.max(messageCount - 10, 1);
    if (index >= fadeIndex) return fadeEnd;
    if (index < fadeStart) return fadeStart;

    return fadeStart + ((index - fadeStart) / (fadeIndex - fadeStart)) * (fadeEnd - fadeStart);
  };

  return (
    <div style={styles.container}>
      <div
        style={styles.scrollView}
        ref={scrollViewRef}
      >
  {messages.flatMap((msgGroup) => msgGroup.messages).map((message, index) => {
          const opacity = calculateOpacity(index);
          const isArtistIcon2Visible = artistUsernames.includes(`@${message.username}`) || artistUsernames.includes(message.username);

          return (
            <div
              key={index}
              style={{
                ...styles.messageContainer,
                opacity,
              }}
            >
              <img
                src={message.profileImageUrl || DefaultsProfilePic}
                alt={`${message.username}'s profile`}
                style={styles.profileImage}
              />
              <div style={styles.textWrapper}>
                <div style={styles.usernameWrapper}>
                  <span style={styles.usernameText}>@{message.username}</span>
                  {message.accountType !== 'Fan' && (
                    <img
                      src={
                        isArtistIcon2Visible
                          ? ArtistIcon2
                          : ArtistIcon1
                      }
                      alt="Account type icon"
                      style={styles.profilePicIcon}
                    />
                  )}
                </div>
                <span style={styles.messageText}>{message.message}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    overflowY: 'hidden',
    height: '90%',
    width: '100%',
    bottom: '10%',
    padding: '10px',
  },
  scrollView: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    padding: '8px',
    borderColor: '#333',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    opacity: 1,
  },
  profileImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  usernameWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  profilePicIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '5px',
  },
  usernameText: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '14px',
  },
  messageText: {
    color: '#fff',
    fontSize: '14px',
  },
};

export default ChatDisplay;
